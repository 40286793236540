<template>
  <button
    class="focus:outline-none"
    @click.stop="clicked">
    <label
      class="flex justify-center items-center"
      ref="switch"
    >
      <switches
        v-model="checked"
        color="blue"
        theme="custom"
        type-bold="true"
        :disabled="disabled"
      />
    </label>
  </button>
</template>

<script>
  import switches from 'vue-switches';

  export default {
    name: 'VSwitch',
    components: {
      switches,
    },
    props: {
      value: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
    },
    data() {
      return {
        checked: false,
      };
    },
    watch: {
      value() {
        this.checked = this.value;
      },
      checked() {
        this.$emit('input', this.checked);
      },
    },
    mounted() {
      this.checked = this.value;
    },
    methods: {
      clicked() {
        if (this.isIE()) {
          this.$refs.switch.click();
        }
      },
      isIE() {
        const { userAgent } = navigator;

        return userAgent.indexOf('MSIE ') > 0 || !!userAgent.match(/Trident.*rv:11\./);
      },
    },
  };
</script>

<style lang="scss" scoped>
  /deep/ .vue-switcher input {
    left: 0;
  }

  /deep/ .vue-switcher--bold div {
    @apply top-0;
  }

  /deep/ .vue-switcher-theme--custom {
    &.vue-switcher-color--blue {
      div {
        @apply bg-blue-500;

        &:after {
          @apply bg-blue-700;
        }
      }

      &.vue-switcher--unchecked {
        div {
          @apply bg-blue-300;

          &:after {
            @apply bg-blue-500;
          }
        }
      }
    }
  }
</style>
