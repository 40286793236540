var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fixed inset-0 z-50 h-screen w-screen flex" },
    [
      _c("div", {
        staticClass: "w-3/4",
        class: { overlay: _vm.overlay },
        on: {
          click: function($event) {
            return _vm.$emit("overlay")
          }
        }
      }),
      _c(
        "div",
        {
          staticClass:
            "h-full w-1/4 bg-white m-0 top-0 right-0 absolute border-l border-blue-500"
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }