import Morphism from 'morphism';

/**
 * Broadcast class
 *
 * @property {?String} channel
 * @property {?String} presenceChannel
 * @property {?Array<String>} events
 */

export default class Broadcast {
  constructor() {
    this.channel = null;
    this.presenceChannel = null;
    this.events = [];
  }

  static fromJson(json) {
    const schema = {
      channel: 'channel',
      presenceChannel: 'presence_channel',
      events: 'events',
    };

    return Morphism(schema, json, Broadcast);
  }
}
