import Morphism from 'morphism';
import Link from './Link';

/**
 * Incident Type class
 *
 * @property {?Number} id
 * @property {?String} name
 *
 * @property {?Number} phone
 * @property {?Boolean} callRequired
 *
 * @property {?String} color
 *
 * @property {?Array<Link>} links
 */

export default class IncidentType {
  constructor() {
    this.id = null;
    this.name = null;

    this.phone = null;
    this.callRequired = false;

    this.color = null;

    this.links = [];
  }

  static fromJson(json) {
    const schema = {
      id: 'id',
      name: 'name',
      phone: 'phone',
      callRequired: 'call_required',
      color: 'color',
      links: (data) => {
        if (data._links) {
          return [];
        }

        return Link.fromJson(data._links);
      },
    };

    return Morphism(schema, json, IncidentType);
  }
}
