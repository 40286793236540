var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-panel",
    {
      attrs: { overlay: !_vm.showTeamsPanel && !_vm.showIncidentTypesPanel },
      on: { overlay: _vm.close }
    },
    [
      _vm.showIncidentTypesPanel
        ? _c("incident-types-filter-panel", {
            on: { close: _vm.closeIncidentTypesPanel, closeAll: _vm.close }
          })
        : _vm._e(),
      _vm.showTeamsPanel
        ? _c("teams-filter-panel", {
            on: { close: _vm.closeTeamsPanel, closeAll: _vm.close }
          })
        : _vm._e(),
      _c("div", { staticClass: "flex flex-col h-full" }, [
        _c(
          "div",
          { staticClass: "flex items-center p-4 border-b border-blue-500" },
          [
            _c(
              "button",
              { staticClass: "focus:outline-none", on: { click: _vm.close } },
              [
                _c("img", {
                  staticClass: "h-7 w-7",
                  attrs: {
                    src: require("@/assets/images/close.svg"),
                    alt: _vm.$t("general.button_close")
                  }
                })
              ]
            ),
            _c("div", { staticClass: "flex-1 mr-7" }, [
              _c("p", {
                staticClass: "font-semibold text-center",
                domProps: {
                  textContent: _vm._s(_vm.$t("filters_panel.label_filters"))
                }
              })
            ])
          ]
        ),
        _c("div", { staticClass: "flex-grow" }, [
          _c("div", { staticClass: "flex flex-col" }, [
            _c(
              "button",
              {
                staticClass: "focus:outline-none",
                class: { "focus:bg-gray-100": _vm.incidentsEnabled },
                on: { click: _vm.openIncidentTypesPanel }
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "flex flex-row justify-between items-center\n              ml-4 pr-4 py-3 border-b border-blue-500"
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("filters_panel.label_incidents")
                        )
                      }
                    }),
                    _c(
                      "span",
                      { staticClass: "flex items-center" },
                      [
                        _c("v-switch", {
                          model: {
                            value: _vm.incidentsEnabled,
                            callback: function($$v) {
                              _vm.incidentsEnabled = $$v
                            },
                            expression: "incidentsEnabled"
                          }
                        }),
                        _vm.incidentsEnabled
                          ? _c("img", {
                              staticClass: "h-4 w-4 ml-1",
                              attrs: {
                                src: require("@/assets/images/ic_chevron_right_blue.svg"),
                                alt: _vm.$t("filters_panel.label_incidents")
                              }
                            })
                          : _vm._e(),
                        !_vm.incidentsEnabled
                          ? _c("img", {
                              staticClass: "h-4 w-4 ml-1",
                              attrs: {
                                src: require("@/assets/images/ic_chevron_right_gray.svg"),
                                alt: _vm.$t("filters_panel.label_incidents")
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _c(
              "button",
              {
                staticClass: "focus:outline-none",
                class: { "focus:bg-gray-100": _vm.usersEnabled },
                on: { click: _vm.openTeamsPanel }
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "flex flex-row justify-between\n              items-center ml-4 pr-4 py-3 border-b border-blue-500"
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("filters_panel.label_users"))
                      }
                    }),
                    _c(
                      "span",
                      { staticClass: "flex items-center" },
                      [
                        _c("v-switch", {
                          model: {
                            value: _vm.usersEnabled,
                            callback: function($$v) {
                              _vm.usersEnabled = $$v
                            },
                            expression: "usersEnabled"
                          }
                        }),
                        _vm.usersEnabled
                          ? _c("img", {
                              staticClass: "h-4 w-4 ml-1",
                              attrs: {
                                src: require("@/assets/images/ic_chevron_right_blue.svg"),
                                alt: _vm.$t("filters_panel.label_users")
                              }
                            })
                          : _vm._e(),
                        !_vm.usersEnabled
                          ? _c("img", {
                              staticClass: "h-4 w-4 ml-1",
                              attrs: {
                                src: require("@/assets/images/ic_chevron_right_gray.svg"),
                                alt: _vm.$t("filters_panel.label_users")
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          ]),
          _c("p", {
            staticClass: "m-4 text-gray-500 text-sm",
            domProps: {
              textContent: _vm._s(_vm.$t("filters_panel.label_performance"))
            }
          })
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }