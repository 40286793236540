<template>
  <div class="h-full relative">
    <div
      id="map"
      ref="map"
      class="h-full"
      :class="{'overlay': user === null}"></div>
    <div
      v-if="newIncidentMarker !== null"
      class="absolute ml-auto w-1/2 bottom-0 flex justify-center left-1/2 pointer-events-none"
    >
      <div
        class="w-full bg-white mb-12 rounded-full shadow-md
          my-3 px-3 pt-2 relative -left-1/2 pointer-events-auto"
      >
        <input
          type="range"
          class="w-full"
          min="0"
          max="50"
          v-model="incidentRange"
          @change="rangeUpdated"
        >
      </div>
    </div>
    <user-info-window
      v-if="selectedUserId"
      :id="selectedUserId"
      @close="closeUserInfoWindow"/>
    <filters-control
      v-if="user !== null && event !== null"
      class="absolute top-0 right-0 mt-3 mr-3"
    />
  </div>
</template>

<script>
  import moment from 'moment';
  import gMaps from '@/utils/gMaps';
  import EventBus from '@/eventbus';
  import UserMarker from '@/markers/UserMarker';
  import IncidentMarker from '@/markers/IncidentMarker';
  import FiltersControl from '@/components/controls/FiltersControl';
  import UserInfoWindow from '@/components/infoWindows/UserInfoWindow.vue';

  export default {
    name: 'VMap',
    components: {
      FiltersControl,
      UserInfoWindow,
    },
    data() {
      return {
        userMarkers: {},
        newIncidentMarker: null,
        newIncidentCircle: null,
        incidentRange: 26,
        incidentMarkers: {},
        loading: false,
        incidentTimeouts: {},
        savedBoundingBox: null,
        selectedUserId: null,
      };
    },
    computed: {
      user() {
        return this.$store.getters['auth/user'];
      },
      event() {
        return this.$store.getters['auth/event'];
      },
      incidentsEnabled() {
        return this.$store.getters['filters/incidentsEnabled'];
      },
      usersEnabled() {
        return this.$store.getters['filters/usersEnabled'];
      },
      selectedIncidentTypeIds() {
        return this.$store.getters['filters/selectedIncidentTypeIds'];
      },
      selectedTeamIds() {
        return this.$store.getters['filters/selectedTeamIds'];
      },
    },
    watch: {
      event(newEvent, oldEvent) {
        if (!this.event || (oldEvent && this.event.id !== oldEvent.id)) {
          this.clearUsers();
          this.clearIncidents();
          this.clearTimeouts();
          gMaps.map.overlayMapTypes.clear();
        }

        if (this.event && (!oldEvent || this.event.id !== oldEvent.id)) {
          const bounds = new gMaps.google.maps.LatLngBounds();
          this.event.focusArea.coordinates[0].forEach((coordinates) => {
            bounds.extend({ lat: coordinates[1], lng: coordinates[0] });
          });
          gMaps.map.fitBounds(bounds);

          this.addMapoverlay();
          this.loadUsers();
          this.startListeningForUserEvents();
          this.loadIncidents();
          this.startListeningForIncidentEvents();
        }
      },
      incidentRange() {
        if (this.$route.name !== 'incident-create') {
          return;
        }

        this.newIncidentCircle.setRadius(parseInt(this.incidentRange, 10));
      },
      $route() {
        if (this.$route.name === 'incident-create') {
          this.createNewIncident();
        } else if (this.newIncidentMarker !== null) {
          this.newIncidentMarker.setMap(null);
          this.newIncidentMarker = null;
          this.newIncidentCircle.setMap(null);
          this.newIncidentCircle = null;

          this.incidentRange = 26;
        }

        if (!['incident-detail', 'incident-assign'].includes(this.$route.name)) {
          this.selectedUserId = null;
        }

        this.restoreBoundingBox();
      },
      incidentsEnabled() {
        this.clearIncidents();
        this.loadIncidents();
      },
      usersEnabled() {
        this.clearUsers();
        this.loadUsers();
      },
      selectedIncidentTypeIds() {
        this.clearIncidents();
        this.loadIncidents();
      },
      selectedTeamIds() {
        this.clearUsers();
        this.loadUsers();
      },
    },
    mounted() {
      gMaps
        .init(this.$refs.map, {
          zoom: 14,
          maxZoom: 20,
          center: {
            lat: 50.846759,
            lng: 4.352425,
          },
          fullscreenControl: false,
          streetViewControl: false,
          clickableIcons: false,
        })
        .then(() => {
          if (this.event === null && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              gMaps.map.setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            });
          }

          gMaps.map.addListener('idle', this.loadItems);
          gMaps.map.addListener('idle', this.boundingBoxUpdated);

          EventBus.$on('center_changed', (center) => {
            if (!center || center.lat === null || center.lng === null) {
              return;
            }

            gMaps.map.setCenter(center);
          });

          EventBus.$on('zoom_changed', (zoom) => {
            if (zoom === null || Number.isNaN(parseInt(zoom, 10))) {
              return;
            }

            gMaps.map.setZoom(parseInt(zoom, 10));
          });

          EventBus.$on('user_selected', this.openUserInfoWindow);

          gMaps.map.addListener('click', (event) => {
            this.locationUpdated(event.latLng);
          });
        });
    },
    beforeDestroy() {
      this.clearTimeouts();
    },
    methods: {
      clearUsers() {
        this.$store.commit('user/clearMapList');

        Object.values(this.userMarkers).forEach((marker) => {
          marker.setMap(null);
        });
        this.userMarkers = {};
      },
      clearIncidents() {
        this.$store.commit('incident/clearMapList');

        Object.values(this.incidentMarkers).forEach((marker) => {
          marker.setMap(null);
        });
        this.incidentMarkers = {};

        this.clearTimeouts();
      },
      clearTimeouts() {
        Object.values(this.incidentTimeouts).forEach((timeout) => {
          clearTimeout(timeout);
        });
      },
      loadItems() {
        if (this.loading) {
          return;
        }

        this.loading = true;
        Promise.all([this.loadUsers(), this.loadIncidents()]).then(() => {
          this.loading = false;
        });
      },
      loadUsers() {
        if (this.event === null || !gMaps.map || !gMaps.map.getBounds()) {
          return Promise.resolve();
        }

        const bounds = gMaps.map.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();

        return this.$store
          .dispatch('user/loadMapListForEventWithinBox', {
            eventId: this.event.id,
            topLeft: { lat: northEast.lat(), lng: southWest.lng() },
            bottomRight: { lat: southWest.lat(), lng: northEast.lng() },
          })
          .then(() => {
            this.$store.getters['user/mapList'].forEach((user) => {
              this.createUserMarker(user);
            });
          });
      },
      loadIncidents() {
        if (this.event == null || !gMaps.map || !gMaps.map.getBounds()) {
          return Promise.resolve();
        }

        const bounds = gMaps.map.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();

        return this.$store
          .dispatch('incident/loadMapListByBoundingBox', {
            topLeft: { lat: northEast.lat(), lng: southWest.lng() },
            bottomRight: { lat: southWest.lat(), lng: northEast.lng() },
          })
          .then(() => {
            this.$store.getters['incident/mapList'].forEach((incident) => {
              this.createIncidentMarker(incident);
            });
          });
      },
      startListeningForUserEvents() {
        EventBus.$on('user_duty_started', this.userDutyStarted);

        EventBus.$on('user_location_updated', this.userLocationUpdated);

        EventBus.$on('user_duty_ended', this.userDutyEnded);
      },
      startListeningForIncidentEvents() {
        EventBus.$on('incident_created', this.incidentCreated);

        EventBus.$on('incident_resolved', this.incidentResolved);
      },
      userDutyStarted(user) {
        if (!this.showUser(user)) {
          return;
        }

        if (this.userMarkers[user.id] === undefined) {
          this.createUserMarker(user);

          return;
        }

        this.userMarkers[user.id].setMap(gMaps.map);
        this.userLocationUpdated(user);
      },
      userLocationUpdated(user) {
        if (!this.showUser(user) || user.lat === null || user.long === null) {
          return;
        }

        if (this.userMarkers[user.id] === undefined) {
          this.createUserMarker(user);

          return;
        }

        if (user.avatar !== null) {
          this.userMarkers[user.id].updateAvatar(user.avatar.small);
        }

        this.userMarkers[user.id].setPosition(
          new gMaps.google.maps.LatLng({
            lat: user.lat,
            lng: user.long,
          }),
        );
      },
      userDutyEnded(user) {
        if (!this.showUser(user) || this.userMarkers[user.id] === undefined) {
          return;
        }

        this.userMarkers[user.id].setMap(null);
      },
      incidentCreated(incident) {
        if (!this.showIncident(incident)) {
          return;
        }

        if (this.incidentMarkers[incident.id] !== undefined) {
          return;
        }

        this.createIncidentMarker(incident);
      },
      incidentResolved(incident) {
        if (!this.showIncident(incident)) {
          return;
        }

        if (this.incidentMarkers[incident.id] === undefined) {
          this.createIncidentMarker(incident);

          return;
        }

        this.incidentMarkers[incident.id].setResolved();
        this.createIncidentTimeout(incident);
      },
      addMapoverlay() {
        if (this.event === null || this.event.overlayUrl === null) {
          return;
        }

        gMaps.addMapOverlay(this.event.overlayUrl);
      },
      createUserMarker(user) {
        if (user.lat === null || user.long === null) {
          return;
        }

        if (this.userMarkers[user.id] !== undefined) {
          if (user.avatar !== null) {
            this.userMarkers[user.id].updateAvatar(user.avatar.small);
          }

          return;
        }

        this.userMarkers[user.id] = UserMarker.make(
          user,
          gMaps.map,
          gMaps.google,
        );
      },
      rangeUpdated() {
        EventBus.$emit('new_incident_radius_updated', this.incidentRange);
      },
      locationUpdated(latlng) {
        if (this.$route.name !== 'incident-create') {
          return;
        }

        const chosenLocation = {
          lat: latlng.lat(),
          lng: latlng.lng(),
        };

        this.newIncidentMarker.setPosition(chosenLocation);

        EventBus.$emit('new_incident_location_updated', chosenLocation);
        EventBus.$emit('new_incident_radius_updated', this.incidentRange);
      },
      createNewIncident() {
        this.newIncidentMarker = new gMaps.google.maps.Marker({
          map: gMaps.map,
          position: gMaps.map.getCenter(),
        });
        this.newIncidentCircle = new gMaps.google.maps.Circle({
          map: gMaps.map,
          radius: this.incidentRange,
          fillColor: '#BDD3EC',
          strokeColor: '#7BA8DA',
          clickable: false,
        });

        this.newIncidentCircle.bindTo(
          'center',
          this.newIncidentMarker,
          'position',
        );

        EventBus.$emit('new_incident_location_updated', {
          lat: gMaps.map.getCenter().lat(),
          lng: gMaps.map.getCenter().lng(),
        });
        EventBus.$emit('new_incident_radius_updated', this.incidentRange);
      },
      createIncidentMarker(incident) {
        if (this.incidentMarkers[incident.id] !== undefined) {
          return;
        }

        this.incidentMarkers[incident.id] = IncidentMarker.make(
          incident,
          gMaps.map,
          gMaps.google,
        );

        if (incident.disappearsAt !== null) {
          this.createIncidentTimeout(incident);
        }
      },
      createIncidentTimeout(incident) {
        if (this.incidentTimeouts[incident.id] !== undefined) {
          clearTimeout(this.incidentTimeouts[incident.id]);
          this.incidentTimeouts[incident.id] = undefined;
        }

        this.incidentTimeouts[incident.id] = setTimeout(() => {
          this.removeIncidentMarker(incident);
        }, incident.disappearsAt.diff(moment()));
      },
      removeIncidentMarker(incident) {
        if (this.incidentMarkers[incident.id] === undefined) {
          return;
        }

        this.incidentMarkers[incident.id].setMap(null);
        delete this.incidentMarkers[incident.id];
      },
      showUser(user) {
        if (!this.usersEnabled || user.teams === null) {
          return false;
        }

        if (this.selectedTeamIds === null) {
          return true;
        }

        const selectedIds = this.selectedTeamIds;

        return user.teams.filter(team => selectedIds.includes(team.id)).length > 0;
      },
      showIncident(incident) {
        if (!this.incidentsEnabled) {
          return false;
        }

        if (this.selectedIncidentTypeIds === null) {
          return true;
        }

        const selectedIds = this.selectedIncidentTypeIds;

        return incident.incidentTypes.filter(team => selectedIds.includes(team.id)).length > 0;
      },
      boundingBoxUpdated() {
        const excludedRoutes = [
          'incident-create',
          'incident-detail',
          'user-detail',
          'incident-assign',
        ];

        if (excludedRoutes.includes(this.$route.name) || !gMaps.map) {
          return;
        }

        this.savedBoundingBox = {
          lat: gMaps.map.getCenter().lat(),
          long: gMaps.map.getCenter().lng(),
          zoom: gMaps.map.getZoom(),
        };
      },
      restoreBoundingBox() {
        const excludedRoutes = [
          'incident-create',
          'incident-detail',
          'user-detail',
          'incident-assign',
        ];

        if (!this.savedBoundingBox || excludedRoutes.includes(this.$route.name) || !gMaps.map) {
          return;
        }

        gMaps.map.setCenter({
          lat: this.savedBoundingBox.lat,
          lng: this.savedBoundingBox.long,
        });
        gMaps.map.setZoom(this.savedBoundingBox.zoom);
      },
      openUserInfoWindow(userId) {
        if (!['incident-detail', 'incident-assign'].includes(this.$route.name)) {
          return;
        }

        this.selectedUserId = userId;
      },
      closeUserInfoWindow() {
        this.selectedUserId = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .overlay {
    @apply relative;

    &:before {
      @apply absolute top-0 left-0 opacity-100 z-10 w-full h-full;

      content: "";
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
</style>
