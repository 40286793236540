import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import store from '@/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: null,
      beforeEnter(to, from, next) {
        if (!store.getters['auth/eventCheck']) {
          return next({ name: 'account-detail' });
        }

        return next({ name: 'incidents-overview' });
      },
      meta: {
        auth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/TheLogin.vue'),
      meta: {
        guest: true,
      },
    },
    {
      path: '/password/reset',
      name: 'password-reset',
      component: () => import('@/views/ThePasswordReset.vue'),
      meta: {
        guest: true,
      },
    },
    {
      path: '/incidents',
      name: 'incidents-overview',
      component: () => import('@/views/TheIncidentsOverview.vue'),
      meta: {
        auth: true,
        event: true,
      },
    },
    {
      path: '/teams',
      name: 'teams-overview',
      component: () => import('@/views/TheTeamsOverview.vue'),
      meta: {
        auth: true,
        event: true,
      },
    },
    {
      path: '/team/create',
      name: 'team-create',
      component: () => import('@/views/TheTeamCreation.vue'),
      meta: {
        auth: true,
        event: true,
        previousRoutes: [
          'teams-overview',
        ],
      },
    },
    {
      path: '/incident/create',
      name: 'incident-create',
      component: () => import('@/views/TheIncidentCreation.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/incident/:id',
      name: 'incident-detail',
      component: () => import('@/views/TheIncidentDetail.vue'),
      meta: {
        auth: true,
        previousRoutes: [
          'incidents-overview',
          'user-detail',
          'incident-detail',
        ],
      },
      props: true,
    },
    {
      path: '/team/:id',
      name: 'team-detail',
      component: () => import('@/views/TheTeamDetail.vue'),
      meta: {
        auth: true,
        event: true,
        previousRoutes: [
          'teams-overview',
        ],
      },
      props: true,
    },
    {
      path: '/team/:id/user/attach',
      name: 'team-user-attach',
      component: () => import('@/views/TheTeamUserAttach.vue'),
      meta: {
        auth: true,
        event: true,
        previousRoutes: [
          'team-detail',
        ],
      },
      props: true,
    },
    {
      path: '/user/:id',
      name: 'user-detail',
      component: () => import('@/views/TheUserDetail.vue'),
      meta: {
        auth: true,
        event: true,
        previousRoutes: [
          'team-detail',
          'incident-detail',
        ],
      },
      props: true,
    },
    {
      path: '/incident/:id/assign',
      name: 'incident-assign',
      component: () => import('@/views/TheIncidentAssign.vue'),
      meta: {
        auth: true,
        event: true,
        previousRoutes: [
          'incidents-overview',
          'incident-detail',
        ],
      },
      props: true,
    },
    {
      path: '/invite',
      name: 'invite',
      component: () => import('@/views/TheInvite.vue'),
      meta: {
        auth: true,
        event: true,
        previousRoutes: [
          'team-detail',
        ],
      },
      props: true,
    },
    {
      path: '/invited-users',
      name: 'invited-users-overview',
      component: () => import('@/views/TheInvitedUsersOverview.vue'),
      meta: {
        auth: true,
        event: true,
        previousRoutes: [
          'teams-overview',
        ],
      },
    },
    {
      path: '/account',
      name: 'account-detail',
      component: () => import('@/views/TheAccountDetail.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/account/edit',
      name: 'account-edit',
      component: () => import('@/views/TheAccountEdit.vue'),
      meta: {
        auth: true,
        previousRoutes: [
          'account-detail',
        ],
      },
    },
    {
      path: '/account/password/edit',
      name: 'account-password-edit',
      component: () => import('@/views/TheAccountPasswordEdit.vue'),
      meta: {
        auth: true,
        previousRoutes: [
          'account-detail',
        ],
      },
    },
    {
      path: '/404',
      name: 'not-found',
      component: () => import('@/views/TheNotFound.vue'),
    },
    {
      path: '/403',
      name: 'not-authorized',
      component: () => import('@/views/TheNotAuthorized.vue'),
      meta: {
        auth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  NProgress.start();

  const browserLocale = (navigator.language || navigator.userLanguage);

  store.dispatch('locale/update', browserLocale)
    .then(() => {
      if (store.getters['auth/check']) {
        return store.dispatch('auth/load')
          .then(() => {
            if (!to.matched.length) {
              return next({ name: 'not-found' });
            }

            if (to.matched.some(record => record.meta.guest)) {
              return next({ name: 'home' });
            }

            if (
              !store.getters['auth/user'].completed
              && to.matched.some(record => record.name !== 'account-edit')
            ) {
              return next({ name: 'account-edit', query: { redirect: to.fullPath } });
            }

            if (!store.getters['auth/event'] && to.matched.some(record => record.meta.event)) {
              return next({ name: 'account-detail' });
            }

            return next();
          }).catch((error) => {
            if (!error.response) {
              return next(false);
            }

            store.dispatch('auth/logout');
            return next({ name: 'home' });
          });
      }

      if (to.matched.some(record => record.meta.auth)) {
        return next({ name: 'login', query: { redirect: to.fullPath } });
      }

      return next();
    });
});

router.afterEach((to, from) => {
  NProgress.done();

  if (!from || from.name === to.name) {
    return;
  }

  const previous = store.getters['routing/last'];

  if (previous && router.matcher.match(previous).fullPath === to.fullPath) {
    store.commit('routing/pop');
  } else if (from.name === null && !previous) {
    store.commit('routing/reset');
    store.commit('routing/push', '/');
  } else if (from.name === null && previous) {
    const previousRoute = router.matcher.match(previous);

    if (!to.meta.previousRoutes || to.meta.previousRoutes.indexOf(previousRoute.name) < 0) {
      store.commit('routing/reset');
      store.commit('routing/push', '/');
    }
  } else if (to.meta.previousRoutes && to.meta.previousRoutes.indexOf(from.name) >= 0) {
    store.commit('routing/push', from.fullPath);
  } else {
    store.commit('routing/reset');
    store.commit('routing/push', '/');
  }
});

export default router;
