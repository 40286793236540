<template>
  <div class="fixed inset-0 z-50 h-screen w-screen flex">
    <div
      class="w-3/4"
      :class="{ 'overlay': overlay }"
      @click="$emit('overlay')">
    </div>
    <div
      class="h-full w-1/4 bg-white m-0 top-0 right-0 absolute border-l border-blue-500"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BasePanel',
    props: {
      overlay: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .overlay {
    @apply relative;

    &:before {
      @apply absolute top-0 left-0 opacity-100 z-50 w-full h-full;

      content: "";
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
</style>
