var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex justify-between items-center bg-red-500 p-4",
      class: { hidden: !_vm.isOffline && !_vm.wasOffline }
    },
    [
      _vm.isOffline
        ? _c("p", {
            staticClass: "text-white",
            domProps: {
              textContent: _vm._s(_vm.$t("vconnectivity.label_no_connection"))
            }
          })
        : _vm._e(),
      !_vm.isOffline && _vm.wasOffline
        ? _c("p", {
            staticClass: "text-white",
            domProps: {
              textContent: _vm._s(
                _vm.$t("vconnectivity.label_no_connection_in_past")
              )
            }
          })
        : _vm._e(),
      _c("button", {
        staticClass:
          "bg-white text-red-500 py-1 px-3 rounded-full focus:outline-none focus:shadow-lg",
        domProps: { textContent: _vm._s(_vm.$t("general.button_reload")) },
        on: { click: _vm.reload }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }