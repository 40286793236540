import moment from 'moment';

export default {
  namespaced: true,
  state: {
    now: moment(),
  },
  mutations: {
    updateTime(state) {
      state.now = moment();
    },
  },
  actions: {
    init({ commit }) {
      setInterval(() => {
        commit('updateTime');
      }, 60 * 1000);
    },
  },
};
