import MessageService from '@/services/MessageService';

export default {
  namespaced: true,
  state: {
    list: null,
    nextPage: 1,
  },
  getters: {
    list: state => state.list,
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    addToList(state, incident) {
      if (!state.list) {
        return;
      }

      const index = state.list.findIndex(item => item.id === incident.id);

      if (index >= 0) {
        return;
      }

      state.list.unshift(incident);
    },
    addMultipleToList(state, items) {
      if (!state.list) {
        return;
      }

      state.list.push(...items);
    },
    setNextPage(state, nextPage) {
      state.nextPage = nextPage;
    },
    clearList(state) {
      state.list = null;
      state.nextPage = 1;
    },
    clearAll(state) {
      state.list = null;
      state.nextPage = 1;
    },
  },
  actions: {
    loadByIncidentId({ state, commit }, incidentId) {
      if (state.nextPage === null) {
        return Promise.resolve();
      }

      return new Promise((resolve, reject) => {
        MessageService.getByIncident(incidentId, state.nextPage)
          .then(({ messages, nextPage }) => {
            if (state.list === null) {
              commit('setList', messages);
            } else {
              commit('addMultipleToList', messages);
            }

            commit('setNextPage', nextPage);

            resolve();
          }).catch(reject);
      });
    },
    create({ commit }, message) {
      return new Promise((resolve, reject) => {
        MessageService.createForIncident(message)
          .then((createdMessage) => {
            commit('addToList', createdMessage);
            resolve(createdMessage);
          }).catch(reject);
      });
    },
  },
};
