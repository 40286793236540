import IncidentService from '@/services/IncidentService';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    item: null,
    list: null,
    mapList: null,
    nextPage: 1,
  },
  getters: {
    item: state => state.item,
    list: state => state.list,
    mapList: state => state.mapList,
  },
  mutations: {
    setItem(state, item) {
      state.item = item;
    },
    addAssignedUserToItem(state, user) {
      if (state.item === null) {
        return;
      }

      const index = state.item.assignedUsers.findIndex(assigned => assigned.id === user.id);

      if (index >= 0) {
        return;
      }

      state.item.assignedUsers.push(user);
    },
    setList(state, list) {
      state.list = list;
    },
    addToList(state, incident) {
      if (state.list === null) {
        return;
      }

      const index = state.list.findIndex(item => item.id === incident.id);

      if (index >= 0) {
        Vue.set(state.list, index, incident);

        return;
      }

      state.list.unshift(incident);
    },
    addMultipleToList(state, incidents) {
      if (state.list === null) {
        return;
      }

      state.list.push(...incidents);
    },
    removeFromList(state, incident) {
      if (state.list === null) {
        return;
      }

      const index = state.list.findIndex(item => item.id === incident.id);

      if (index < 0) {
        return;
      }

      state.list.splice(index, 1);
    },
    updateInList(state, incident) {
      if (state.list === null) {
        return;
      }

      const index = state.list.findIndex(item => item.id === incident.id);

      if (index < 0) {
        return;
      }

      state.list[index] = incident;
    },
    setMapList(state, mapList) {
      state.mapList = mapList;
    },
    setNextPage(state, nextPage) {
      state.nextPage = nextPage;
    },
    clearItem(state) {
      state.item = null;
    },
    clearList(state) {
      state.list = null;
      state.nextPage = 1;
    },
    clearMapList(state) {
      state.mapList = null;
    },
    clearAll(state) {
      state.item = null;
      state.list = null;
      state.mapList = null;
      state.nextPage = 1;
    },
  },
  actions: {
    loadForEvent({ state, commit }, { event, searchValue }) {
      return new Promise((resolve, reject) => {
        if (state.nextPage === null) {
          resolve();
          return;
        }

        IncidentService.getByEvent(event.id, state.nextPage, searchValue)
          .then(({ incidents, nextPage }) => {
            if (state.list === null) {
              commit('setList', incidents);
            } else {
              commit('addMultipleToList', incidents);
            }

            commit('setNextPage', nextPage);

            resolve();
          }).catch(reject);
      });
    },
    loadMapListByBoundingBox({ rootGetters, commit }, boundingBox) {
      if (!rootGetters['filters/incidentsEnabled']) {
        commit('setMapList', []);

        return Promise.resolve();
      }

      const selectedIncidentTypeIds = rootGetters['filters/selectedIncidentTypeIds'];

      return new Promise((resolve, reject) => {
        IncidentService.getByBoundingBox(boundingBox, selectedIncidentTypeIds)
          .then((incidents) => {
            commit('setMapList', incidents);
            resolve();
          }).catch(reject);
      });
    },
    loadById({ commit }, id) {
      return new Promise((resolve, reject) => {
        IncidentService.getById(id)
          .then((incident) => {
            commit('setItem', incident);
            resolve();
          }).catch(reject);
      });
    },
    create(context, incident) {
      return new Promise((resolve, reject) => {
        IncidentService.create(incident)
          .then((newIncident) => {
            resolve(newIncident);
          }).catch(reject);
      });
    },
    assignUser({ commit }, { user, incident }) {
      return new Promise((resolve, reject) => {
        IncidentService.assignUser(user, incident)
          .then((updatedIncident) => {
            commit('setItem', updatedIncident);
            resolve();
          })
          .catch(reject);
      });
    },
    resolve({ commit }, incident) {
      return new Promise((resolve, reject) => {
        IncidentService.resolve(incident)
          .then((resolved) => {
            commit('setItem', resolved);
            resolve();
          }).catch(reject);
      });
    },
  },
};
