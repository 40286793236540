import axios from 'axios';
import User from '@/models/User';

export default class AuthService {
  /**
   * Authenticate as application
   * @returns {Promise<String>}
   */
  static authenticateAsApplication() {
    return new Promise((resolve, reject) => {
      axios.post('/oauth/token', {
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        grant_type: 'client_credentials',
      }).then((response) => {
        resolve(response.data.access_token);
      }).catch(reject);
    });
  }

  /**
   * Authenticate with provided credentials
   * @param   {String} username
   * @param   {String} password
   * @returns {Promise<Object>}
   */
  static authenticate(username, password) {
    return new Promise((resolve, reject) => {
      axios.post('/oauth/token', {
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        grant_type: 'password',
        username,
        password,
      }).then((response) => {
        resolve({
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
        });
      }).catch(reject);
    });
  }

  /**
   * Get current user
   * @returns {Promise<User>}
   */
  static getUser() {
    return new Promise((resolve, reject) => {
      axios.get('/api/user/current')
        .then((response) => {
          resolve(User.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * Update current user
   * @param {User} user
   * @returns {Promise<User>}
   */
  static updateUser(user) {
    return new Promise((resolve, reject) => {
      axios.put('/api/user', {
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone,
        email: user.email,
      }).then((response) => {
        resolve(User.fromJson(response.data.data));
      }).catch(reject);
    });
  }

  /**
   * Update current user avatar
   * @param {File} avatar
   * @returns {Promise<User>}
   */
  static updateUserAvatar(avatar) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('avatar', avatar);

      axios.post('/api/user/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          resolve(User.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * Update current user password
   * @param {User} user
   * @returns {Promise<User>}
   */
  static updateUserPassword(user) {
    return new Promise((resolve, reject) => {
      axios.patch('/api/user/password', {
        current_password: user.currentPassword,
        password: user.password,
        password_confirmation: user.password,
      }).then((response) => {
        resolve(User.fromJson(response.data.data));
      }).catch(reject);
    });
  }

  /**
   * Refresh Access token, returns new access token & refresh token
   * @param {String} refreshToken
   * @returns {Promise<Object>}
   */
  static refreshToken(refreshToken) {
    return new Promise((resolve, reject) => {
      axios.post('/oauth/token', {
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }).then((response) => {
        resolve({
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
        });
      }).catch(reject);
    });
  }

  /**
   * Logout current User
   * @returns {Promise}
   */
  static logout() {
    return new Promise((resolve, reject) => {
      axios.post('/api/logout')
        .then(resolve)
        .catch(reject);
    });
  }

  /**
   * reset password for email
   * @param {String} email
   */
  static resetPassword(email) {
    return new Promise((resolve, reject) => {
      axios.post('/api/password/forgot', {
        email,
      })
        .then(resolve)
        .catch(reject);
    });
  }
}
