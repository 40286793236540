import axios from 'axios';
import Message from '@/models/Message';

export default class MessageService {
  /**
   * Get messages for incident by page number
   * @param {Number} incidentId
   * @param {?Number} pageNumber
   * @returns {Promise<Array<Message>,Number>}
   */
  static getByIncident(incidentId, pageNumber = 1) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/incident/${incidentId}/messages?page=${pageNumber}`)
        .then((response) => {
          resolve({
            messages: Message.fromJson(response.data.data),
            nextPage: response.data.meta.last_page >= pageNumber + 1
              ? pageNumber + 1 : null,
          });
        }).catch(reject);
    });
  }

  /**
   * create message for incident
   * @param {Number} incidentId
   * @param {Message} message
   * @returns {Promise<Message>}
   */
  static createForIncident(message) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/incident/${message.incident.id}/messages`, {
        type: message.type,
        content: message.content,
      })
        .then((response) => {
          resolve(Message.fromJson(response.data.data));
        }).catch(reject);
    });
  }
}
