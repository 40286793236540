import Vue from 'vue';
import VueI18n from 'vue-i18n';
import moment from 'moment';
import en from '../assets/lang/en.json';

Vue.use(VueI18n);

moment.locale('en');

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
  },
});
