import Morphism from 'morphism';
import Event from './Event';
import Link from './Link';

/**
 * Organization class
 *
 * @property {?Number} id
 * @property {?String} name
 *
 * @property {?Array<Event>} events
 *
 * @property {?Array<Link>} links
 */

export default class Organization {
  constructor() {
    this.id = null;
    this.name = null;

    this.events = [];

    this.links = [];
  }

  static fromJson(json) {
    const schema = {
      id: 'id',
      name: 'name',
      events: (data) => {
        if (!data.events) {
          return [];
        }

        return Event.fromJson(data.events);
      },
      links: (data) => {
        if (!data._links) {
          return [];
        }

        return Link.fromJson(data._links);
      },
    };

    return Morphism(schema, json, Organization);
  }
}
