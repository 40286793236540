import cloneDeep from 'lodash.clonedeep';
import TeamService from '@/services/TeamService';
import UserService from '@/services/UserService';

export default {
  namespaced: true,
  state: {
    list: null,
    mapList: null,
    item: null,
    nextPage: 1,
  },
  getters: {
    list: state => state.list,
    mapList: state => state.mapList,
    item: state => state.item,
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    setMapList(state, mapList) {
      state.mapList = mapList;
    },
    setItem(state, item) {
      state.item = item;
    },
    updateItem(state, updatedItem) {
      if (!state.item || state.item.id !== updatedItem.id) {
        return;
      }

      state.item.firstname = updatedItem.firstname;
      state.item.lastname = updatedItem.lastname;
      state.item.birthdate = updatedItem.birthdate;
      state.item.phone = updatedItem.phone;
      state.item.email = updatedItem.email;
      state.item.avatar = updatedItem.avatar;

      if (updatedItem.onDuty && !updatedItem.onDuty) {
        state.item.distance = null;
      }

      state.item.onDuty = updatedItem.onDuty;
      state.item.lat = updatedItem.lat;
      state.item.long = updatedItem.long;
      state.item.phoneVerified = updatedItem.phoneVerified;
      state.item.emailVerified = updatedItem.emailVerified;

      if (updatedItem.assignedIncidentsCount) {
        state.item.assignedIncidentsCount = updatedItem.assignedIncidentsCount;
      }

      state.item.links = cloneDeep(updatedItem.links);
    },
    addMultipleToList(state, users) {
      state.list.push(...users);
    },
    setNextPage(state, nextPage) {
      state.nextPage = nextPage;
    },
    clearItem(state) {
      state.item = null;
    },
    clearList(state) {
      state.list = null;
      state.nextPage = 1;
    },
    clearMapList(state) {
      state.mapList = null;
    },
    clearAll(state) {
      state.item = null;
      state.list = null;
      state.mapList = null;
      state.nextPage = 1;
    },
  },
  actions: {
    loadMapListForEventWithinBox({ rootGetters, commit }, { eventId, topLeft, bottomRight }) {
      if (!rootGetters['filters/usersEnabled']) {
        commit('setMapList', []);

        return Promise.resolve();
      }

      const selectedTeamIds = rootGetters['filters/selectedTeamIds'];

      return new Promise((resolve, reject) => {
        UserService.getByEventIdWithinBox(eventId, { topLeft, bottomRight }, selectedTeamIds)
          .then((users) => {
            commit('setMapList', users);
            resolve();
          }).catch(reject);
      });
    },
    loadById({ commit }, { id, eventId }) {
      return new Promise((resolve, reject) => {
        UserService.getById(id, eventId)
          .then((user) => {
            commit('setItem', user);
            resolve();
          }).catch(reject);
      });
    },
    searchForEvent({ state, commit }, { eventId, searchValue }) {
      if (state.nextPage === null) {
        return Promise.resolve();
      }

      return new Promise((resolve, reject) => {
        UserService.searchEvent(eventId, searchValue, state.nextPage)
          .then(({ users, nextPage }) => {
            if (state.list === null) {
              commit('setList', users);
            } else {
              commit('addMultipleToList', users);
            }

            commit('setNextPage', nextPage);

            resolve();
          }).catch(reject);
      });
    },
    searchForEventByCoordinates({ commit, state }, {
      eventId, lat, long, searchValue,
    }) {
      if (state.nextPage === null) {
        return Promise.resolve();
      }

      return new Promise((resolve, reject) => {
        UserService.searchEventByCoordinates(eventId, { lat, long }, searchValue, state.nextPage)
          .then(({ users, nextPage }) => {
            if (state.list === null) {
              commit('setList', users);
            } else {
              commit('addMultipleToList', users);
            }

            commit('setNextPage', nextPage);

            resolve();
          }).catch(reject);
      });
    },
    searchForTeam({ state, commit }, { teamId, searchValue }) {
      if (state.nextPage === null) {
        return Promise.resolve();
      }

      return new Promise((resolve, reject) => {
        UserService.searchTeam(teamId, searchValue, state.nextPage)
          .then(({ users, nextPage }) => {
            if (state.list === null) {
              commit('setList', users);
            } else {
              commit('addMultipleToList', users);
            }

            commit('setNextPage', nextPage);

            resolve();
          }).catch(reject);
      });
    },
    searchForTeamByCoordinates({ commit, state }, {
      teamId, lat, long, searchValue,
    }) {
      if (state.nextPage === null) {
        return Promise.resolve();
      }

      return new Promise((resolve, reject) => {
        UserService.searchTeamByCoordinates(teamId, { lat, long }, searchValue, state.nextPage)
          .then(({ users, nextPage }) => {
            if (state.list === null) {
              commit('setList', users);
            } else {
              commit('addMultipleToList', users);
            }

            commit('setNextPage', nextPage);

            resolve();
          }).catch(reject);
      });
    },
    loadInvitedForEvent({ state, commit }, eventId) {
      if (state.nextPage === null) {
        return Promise.resolve();
      }

      return new Promise((resolve, reject) => {
        UserService.getAllInvitedByEventId(eventId, state.nextPage)
          .then(({ users, nextPage }) => {
            if (state.list === null) {
              commit('setList', users);
            } else {
              commit('addMultipleToList', users);
            }

            commit('setNextPage', nextPage);

            resolve();
          }).catch(reject);
      });
    },
    invite(context, { user, team }) {
      return new Promise((resolve, reject) => {
        UserService.invite(user, team)
          .then(resolve)
          .catch(reject);
      });
    },
    removeFromTeam(context, { user, team }) {
      return new Promise((resolve, reject) => {
        TeamService.removeUser(team, user)
          .then(resolve)
          .catch(reject);
      });
    },
    startDuty({ commit }, { user, eventId }) {
      return new Promise((resolve, reject) => {
        UserService.startDuty(user, eventId)
          .then((updatedUser) => {
            commit('setItem', updatedUser);
            resolve();
          }).catch(reject);
      });
    },
    endDuty({ commit }, { user, eventId }) {
      return new Promise((resolve, reject) => {
        UserService.endDuty(user, eventId)
          .then((updatedUser) => {
            commit('setItem', updatedUser);
            resolve();
          }).catch(reject);
      });
    },
  },
};
