import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    history: sessionStorage.getItem('routing_history') !== null ? JSON.parse(sessionStorage.getItem('routing_history')) : [],
  },
  getters: {
    last: (state) => {
      if (state.history.length > 0) {
        return state.history[state.history.length - 1];
      }

      return null;
    },
  },
  mutations: {
    push(state, route) {
      state.history.push(route);

      sessionStorage.setItem('routing_history', JSON.stringify(state.history));
    },
    pop(state) {
      Vue.delete(state.history, state.history.length - 1);

      sessionStorage.setItem('routing_history', JSON.stringify(state.history));
    },
    reset(state) {
      state.history = [];

      sessionStorage.setItem('routing_history', JSON.stringify(state.history));
    },
  },
};
