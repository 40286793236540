import axios from 'axios';
import User from '@/models/User';

export default class UserService {
  /**
  * Get all users for event
  * @param {Number} eventId
  * @param {Object} boundingBox
  * @param {null|Array} teamIds
  * @returns {Promise<Array<User>>}
  */
  static getByEventIdWithinBox(eventId, { topLeft, bottomRight }, teamIds = null) {
    let querystring = `bounding_box[top_left]=${topLeft.lat},${topLeft.lng}&bounding_box[bottom_right]=${bottomRight.lat},${bottomRight.lng}`;

    if (teamIds !== null) {
      querystring = `${querystring}&team_ids=${teamIds.join(',')}`;
    }

    return new Promise((resolve, reject) => {
      axios.get(`/api/event/${eventId}/users?${querystring}`)
        .then((response) => {
          resolve(User.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * get by id
   * @param {Number} id
   * @param {Number?} eventId
   * @returns {Promise<User>}
   */
  static getById(id, eventId = null) {
    return new Promise((resolve, reject) => {
      const queryString = eventId !== null ? `event_id=${eventId}` : '';

      axios.get(`/api/user/${id}?${queryString}`)
        .then((response) => {
          resolve(User.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * Search for users in event
   * @param {Number} eventId
   * @param {?String} searchValue
   * @param {Number} page
   * @returns {Promise<Array<User>>}
   */
  static searchEvent(eventId, searchValue = null, page = 1) {
    return new Promise((resolve, reject) => {
      let querystring = `page=${page}`;

      if (searchValue) {
        querystring = `${querystring}&q=${searchValue}`;
      }

      axios.get(`/api/event/${eventId}/users/search?${querystring}`)
        .then((response) => {
          resolve({
            users: User.fromJson(response.data.data),
            nextPage: (response.data.meta.last_page >= page + 1) ? page + 1 : null,
          });
        }).catch(reject);
    });
  }

  /**
   * Search for users in event
   * @param {Number} eventId
   * @param {Object} location
   * @param {String} searchValue
   * @param {Number} page
   * @returns {Promise<Array<User>>}
   */
  static searchEventByCoordinates(eventId, { lat, long }, searchValue, page = 1) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/event/${eventId}/users/search?q=${searchValue}&lat=${lat}&long=${long}&page=${page}`)
        .then((response) => {
          resolve({
            users: User.fromJson(response.data.data),
            nextPage: (response.data.meta.last_page >= page + 1) ? page + 1 : null,
          });
        }).catch(reject);
    });
  }

  /**
   * Search for users in team
   * @param {Number} teamId
   * @param {String} searchValue
   * @param {Number} page
   * @returns {Promise<Array<User>>}
   */
  static searchTeam(teamId, searchValue, page = 1) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/team/${teamId}/users?q=${searchValue}&page=${page}`)
        .then((response) => {
          resolve({
            users: User.fromJson(response.data.data),
            nextPage: (response.data.meta.last_page >= page + 1) ? page + 1 : null,
          });
        }).catch(reject);
    });
  }

  /**
   * Search users in team (order users by distance of location)
   * @param {Number} teamId
   * @param {Object} location
   * @param {String} searchValue
   * @param {Number} page
   * @returns {Promise<Array<User>>}
   */
  static searchTeamByCoordinates(teamId, { lat, long }, searchValue, page = 1) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/team/${teamId}/users?lat=${lat}&long=${long}&q=${searchValue}`)
        .then((response) => {
          resolve({
            users: User.fromJson(response.data.data),
            nextPage: (response.data.meta.last_page >= page + 1) ? page + 1 : null,
          });
        }).catch(reject);
    });
  }

  /**
   * Invite user to team
   * @param {User} user
   * @param {Team} team
   * @returns {Promise<User>}
   */
  static invite(user, team) {
    return new Promise((resolve, reject) => {
      let data = {
        role: user.role,
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone,
        email: user.email,
      };

      if (user.id) {
        data = {
          user_id: user.id,
          role: user.role,
        };
      }

      axios.post(`/api/team/${team.id}/users`, data)
        .then((response) => {
          resolve(User.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * Get all invited users for event
   * @param {Number} eventId
   * @param {Number} page
   * @returns {Promise<Array<User>>}
   */
  static getAllInvitedByEventId(eventId, page = 1) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/event/${eventId}/invited-users?page=${page}`)
        .then((response) => {
          resolve({
            users: User.fromJson(response.data.data),
            nextPage: (response.data.meta.last_page >= page + 1) ? page + 1 : null,
          });
        }).catch(reject);
    });
  }

  /**
   * Start duty of user
   * @param {User} user
   * @param {?Number} eventId
   * @returns {Promise<User>}
   */
  static startDuty(user, eventId = null) {
    return new Promise((resolve, reject) => {
      const queryString = eventId !== null ? `event_id=${eventId}` : '';

      axios.post(`/api/user/${user.id}/duty?${queryString}`)
        .then((response) => {
          resolve(User.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * End duty of user
   * @param {User} user
   * @param {?Number} eventId
   * @returns {Promise<User>}
   */
  static endDuty(user, eventId = null) {
    return new Promise((resolve, reject) => {
      const queryString = eventId !== null ? `event_id=${eventId}` : '';

      axios.delete(`/api/user/${user.id}/duty?${queryString}`)
        .then((response) => {
          resolve(User.fromJson(response.data.data));
        }).catch(reject);
    });
  }
}
