var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full relative" },
    [
      _c("div", {
        ref: "map",
        staticClass: "h-full",
        class: { overlay: _vm.user === null },
        attrs: { id: "map" }
      }),
      _vm.newIncidentMarker !== null
        ? _c(
            "div",
            {
              staticClass:
                "absolute ml-auto w-1/2 bottom-0 flex justify-center left-1/2 pointer-events-none"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-full bg-white mb-12 rounded-full shadow-md\n        my-3 px-3 pt-2 relative -left-1/2 pointer-events-auto"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.incidentRange,
                        expression: "incidentRange"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: { type: "range", min: "0", max: "50" },
                    domProps: { value: _vm.incidentRange },
                    on: {
                      change: _vm.rangeUpdated,
                      __r: function($event) {
                        _vm.incidentRange = $event.target.value
                      }
                    }
                  })
                ]
              )
            ]
          )
        : _vm._e(),
      _vm.selectedUserId
        ? _c("user-info-window", {
            attrs: { id: _vm.selectedUserId },
            on: { close: _vm.closeUserInfoWindow }
          })
        : _vm._e(),
      _vm.user !== null && _vm.event !== null
        ? _c("filters-control", {
            staticClass: "absolute top-0 right-0 mt-3 mr-3"
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }