import Morphism from 'morphism';
import Organization from './Organization';
import Link from './Link';
import Image from './Image';
import Event from './Event';
import Incident from './Incident';
import Team from './Team';

/**
 * User class
 * @property {?Number} id
 * @property {?String} firstname
 * @property {?String} lastname
 * @property {?String} birthdate
 *
 * @property {?String} phone
 * @property {?String} email
 * @property {?Object} avatar
 * @property {?String} role
 *
 * @property {?Boolean} onDuty
 *
 * @property {?Number} lat
 * @property {?Number} long
 * @property {?Number} distance
 *
 * @property {?Number} assignedIncidentsCount
 *
 * @property {?Array<Incident>} assignedIncidents
 *
 * @property {?Boolean} phoneVerified
 * @property {?Boolean} emailVerified
 * @property {?Boolean} completed
 *
 * @property {?Array<Organization>} organizations
 * @property {?Array<Event>} events
 *
 * @property {?Array<Link>} links
 */

export default class User {
  constructor() {
    this.id = null;
    this.firstname = null;
    this.lastname = null;
    this.birthdate = null;

    this.phone = null;
    this.email = null;
    this.avatar = null;
    this.role = null;

    this.onDuty = false;

    this.lat = null;
    this.long = null;
    this.distance = null;

    this.assignedIncidentsCount = null;

    this.assignedIncidents = [];
    this.assignedIncidentsCount = null;

    this.phoneVerified = false;
    this.emailVerified = false;
    this.completed = false;

    this.organizations = [];
    this.events = [];
    this.teams = [];

    this.links = [];
  }

  get name() {
    if (this.firstname === null && this.lastname === null) {
      if (this.phone !== null) {
        return this.phone;
      }

      return this.email;
    }

    return `${this.firstname || ''} ${this.lastname || ''}`;
  }

  get shortName() {
    if (this.firstname !== null) {
      return this.firstname;
    }

    if (this.phone !== null) {
      return this.phone;
    }

    return this.email;
  }

  get canCreateIncident() {
    return this.links.some(link => link.rel === 'create_incident');
  }

  get canStartDuty() {
    return this.links.some(link => link.rel === 'start_duty');
  }

  get canEndDuty() {
    return this.links.some(link => link.rel === 'end_duty');
  }

  static fromJson(json) {
    const schema = {
      id: 'id',
      firstname: 'firstname',
      lastname: 'lastname',
      birthdate: 'birthdate',
      phone: 'phone',
      email: 'email',
      avatar: (data) => {
        if (!data.avatar) {
          return null;
        }

        return Image.fromJson(data.avatar);
      },
      role: 'role',
      onDuty: 'on_duty',
      lat: 'lat',
      long: 'long',
      distance: 'distance',
      assignedIncidentsCount: 'assigned_incidents_count',
      phoneVerified: 'phone_verified',
      emailVerified: 'email_verified',
      completed: 'completed',
      organizations: (data) => {
        if (!data.organizations) {
          return [];
        }

        return Organization.fromJson(data.organizations);
      },
      events: (data) => {
        if (!data.events) {
          return [];
        }

        return Event.fromJson(data.events);
      },
      assignedIncidents: (data) => {
        if (!data.assigned_incidents) {
          return [];
        }

        return Incident.fromJson(data.assigned_incidents);
      },
      teams: (data) => {
        if (!data.teams) {
          return [];
        }

        return Team.fromJson(data.teams);
      },
      links: (data) => {
        if (!data._links) {
          return [];
        }

        return Link.fromJson(data._links);
      },
    };

    return Morphism(schema, json, User);
  }
}
