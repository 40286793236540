import Morphism from 'morphism';
import User from './User';
import Link from './Link';

/**
 * Team class
 *
 * @property {?Number} id
 * @property {?String} name
 * @property {?String} role
 *
 * @property {?Array<User>} users
 * @property {?Number} usersCount
 *
 * @property {?Array<Link>} links
 */

export default class Team {
  constructor() {
    this.id = null;
    this.name = null;
    this.role = null;

    this.users = [];
    this.usersCount = null;

    this.links = [];
  }

  get canAddUser() {
    return this.links.some(link => link.rel === 'add_user');
  }

  get canRemoveFromUser() {
    return this.links.some(link => link.rel === 'remove_from_user');
  }

  static fromJson(json) {
    const schema = {
      id: 'id',
      name: 'name',
      role: 'role',
      users: (data) => {
        if (!data.users) {
          return [];
        }

        return User.fromJson(data.users);
      },
      usersCount: 'users_count',
      links: (data) => {
        if (!data._links) {
          return [];
        }

        return Link.fromJson(data._links);
      },
    };

    return Morphism(schema, json, Team);
  }
}
