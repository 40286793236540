import Vue from 'vue';
import bugsnag from '@bugsnag/js';
import bugsnagVue from '@bugsnag/plugin-vue';
import VeeValidate from 'vee-validate';
import NProgress from 'nprogress';
import en from 'vee-validate/dist/locale/en';
import VueNativeNotification from 'vue-native-notification';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import i18n from './i18n';
import './http';


if (process.env.VUE_APP_BUGSNAG_RELEASE_STAGE !== 'local') {
  const bugsnagClient = bugsnag({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    beforeSend: (report) => {
      report.app.releaseStage = process.env.VUE_APP_BUGSNAG_RELEASE_STAGE;
      report.app.version = process.env.VUE_APP_VERSION;

      const user = store.getters['auth/user'];

      if (user) {
        report.user = {
          id: user.id,
          eventId: store.state.auth.eventId,
        };
      }
    },
  });

  bugsnagClient.use(bugsnagVue, Vue);
}

NProgress.configure({ showSpinner: false });

Vue.config.productionTip = false;

Vue.use(VeeValidate, {
  i18nRootKey: 'validations',
  i18n,
  inject: false,
  dictionary: {
    en,
  },
});

Vue.use(VueNativeNotification, {
  requestOnNotify: true,
});

new Vue({
  router,
  store,
  i18n,
  created() {
    this.$store.dispatch('time/init');
  },
  render: h => h(App),
}).$mount('#app');
