import Vue from 'vue';
import Vuex from 'vuex';
import locale from './modules/locale';
import auth from './modules/auth';
import team from './modules/team';
import user from './modules/user';
import incident from './modules/incident';
import incidentType from './modules/incidentType';
import time from './modules/time';
import message from './modules/message';
import filters from './modules/filters';
import routing from './modules/routing';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    locale,
    auth,
    team,
    user,
    incident,
    incidentType,
    time,
    message,
    filters,
    routing,
  },
  actions: {
    clearAll({ commit }) {
      commit('team/clearAll');
      commit('user/clearAll');
      commit('incident/clearAll');
      commit('incidentType/clearAll');
      commit('message/clearAll');
      commit('filters/clearAll');
    },
  },
});
