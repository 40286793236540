var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-panel", { on: { overlay: _vm.closeAll } }, [
    _c(
      "div",
      { staticClass: "flex flex-col h-full" },
      [
        _c(
          "div",
          { staticClass: "flex items-center p-4 border-b border-blue-500" },
          [
            _c(
              "button",
              { staticClass: "focus:outline-none", on: { click: _vm.close } },
              [
                _c("img", {
                  staticClass: "h-7 w-7",
                  attrs: {
                    src: require("@/assets/images/back.svg"),
                    alt: _vm.$t("general.button_back")
                  }
                })
              ]
            ),
            _c("div", { staticClass: "flex-1 mr-7" }, [
              _c("p", {
                staticClass: "font-semibold text-center",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("incident_types_filter_panel.label_incident_types")
                  )
                }
              })
            ])
          ]
        ),
        _c("v-scrollable", { staticClass: "flex-grow" }, [
          _c(
            "ul",
            _vm._l(_vm.incidentTypes, function(incidentType) {
              return _c(
                "li",
                {
                  key: incidentType.name,
                  staticClass:
                    "flex flex-row justify-between items-center\n            ml-4 pr-4 py-3 border-b border-blue-500"
                },
                [
                  _c(
                    "label",
                    { staticClass: "flex justify-between w-full" },
                    [
                      _c("div", { staticClass: "flex items-center" }, [
                        _c("span", {
                          staticClass: "flex-none h-7 w-7 rounded-full",
                          style: { "background-color": incidentType.color }
                        }),
                        _c("p", {
                          staticClass: "uppercase ml-2",
                          domProps: { textContent: _vm._s(incidentType.name) }
                        })
                      ]),
                      _c("v-switch", {
                        attrs: {
                          value:
                            _vm.selectedIncidentTypeIds.indexOf(
                              incidentType.id
                            ) >= 0
                        },
                        on: {
                          input: function(checked) {
                            return _vm.incidentTypeToggled(
                              incidentType,
                              checked
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            0
          ),
          _c("p", {
            staticClass: "m-4 text-gray-500 text-sm",
            domProps: {
              textContent: _vm._s(
                _vm.$t("incident_types_filter_panel.label_performance")
              )
            }
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }