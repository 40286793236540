import TeamService from '@/services/TeamService';
import IncidentTypeService from '@/services/IncidentTypeService';

export default {
  namespaced: true,
  state: {
    usersEnabled: localStorage.getItem('filters_users_enabled') !== null ? localStorage.getItem('filters_users_enabled') === 'true' : true,
    incidentsEnabled: localStorage.getItem('filters_incidents_enabled') !== null ? localStorage.getItem('filters_incidents_enabled') === 'true' : true,
    teams: [],
    selectedTeamIds: localStorage.getItem('filters_selected_team_ids') !== null ? localStorage.getItem('filters_selected_team_ids').split(',').map(Number) : null,
    incidentTypes: [],
    selectedIncidentTypeIds: localStorage.getItem('filters_selected_incident_type_ids') !== null ? localStorage.getItem('filters_selected_incident_type_ids').split(',').map(Number) : null,
  },
  getters: {
    usersEnabled: state => state.usersEnabled,
    incidentsEnabled: state => state.incidentsEnabled,
    teams: state => state.teams,
    selectedTeamIds: state => state.selectedTeamIds,
    selectedTeams: (state) => {
      const selectedIds = state.selectedTeamIds;

      if (selectedIds === null) {
        return null;
      }

      return state.teams.filter(team => selectedIds.indexOf(team.id) >= 0);
    },
    incidentTypes: state => state.incidentTypes,
    selectedIncidentTypeIds: state => state.selectedIncidentTypeIds,
    selectedIncidentTypes: (state) => {
      const selectedIds = state.selectedIncidentTypeIds;

      if (selectedIds === null) {
        return null;
      }

      return state.incidentTypes.filter(team => selectedIds.indexOf(team.id) >= 0);
    },
  },
  mutations: {
    updateUsersEnabled(state, usersEnabled) {
      state.usersEnabled = usersEnabled;
      localStorage.setItem('filters_users_enabled', usersEnabled);
    },
    updateIncidentsEnabled(state, incidentsEnabled) {
      state.incidentsEnabled = incidentsEnabled;
      localStorage.setItem('filters_incidents_enabled', incidentsEnabled);
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setSelectedTeams(state, teams) {
      if (teams === null) {
        state.selectedTeamIds = null;
        localStorage.removeItem('filters_selected_team_ids');

        return;
      }

      state.selectedTeamIds = teams.map(team => team.id);
      localStorage.setItem('filters_selected_team_ids', state.selectedTeamIds);
    },
    setIncidentTypes(state, incidentTypes) {
      state.incidentTypes = incidentTypes;
    },
    setSelectedIncidentTypes(state, incidentTypes) {
      if (incidentTypes === null) {
        state.selectedIncidentTypeIds = null;
        localStorage.removeItem('filters_selected_incident_type_ids');

        return;
      }

      state.selectedIncidentTypeIds = incidentTypes.map(incidentType => incidentType.id);
      localStorage.setItem('filters_selected_incident_type_ids', state.selectedIncidentTypeIds);
    },
    clearTeams(state) {
      state.teams = [];
    },
    clearIncidentTypes(state) {
      state.incidentTypes = [];
    },
    clearAll(state) {
      state.teams = [];
      state.incidentTypes = [];

      state.usersEnabled = true;
      state.incidentsEnabled = true;

      state.selectedTeamIds = null;
      state.selectedIncidentTypeIds = null;

      localStorage.removeItem('filters_selected_team_ids');
      localStorage.removeItem('filters_selected_incident_type_ids');
    },
  },
  actions: {
    loadTeams({ rootGetters, commit }) {
      const event = rootGetters['auth/event'];

      return new Promise((resolve, reject) => {
        TeamService.getByEventId(event.id)
          .then((teams) => {
            commit('setTeams', teams);
            resolve();
          }).catch(reject);
      });
    },
    loadIncidentTypes({ rootGetters, commit }) {
      const event = rootGetters['auth/event'];

      return new Promise((resolve, reject) => {
        IncidentTypeService.getByEventId(event.id)
          .then((incidentTypes) => {
            commit('setIncidentTypes', incidentTypes);
            resolve();
          }).catch(reject);
      });
    },
  },
};
