var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col h-screen font-sans", attrs: { id: "app" } },
    [
      _vm.authenticated
        ? _c("v-connectivity", { staticClass: "w-full" })
        : _vm._e(),
      _vm.authenticated && !_vm.$route.meta.guest
        ? _c("v-menu", { staticClass: "w-full" })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-1" },
        [
          _c(
            "div",
            {
              staticClass:
                "w-1/4 max-h-full border-r border-blue-500 shadow-lg z-30"
            },
            [_c("router-view", { staticClass: "h-full" })],
            1
          ),
          _c("v-map", { staticClass: "w-3/4" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }