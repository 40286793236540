var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full flex justify-center relative" }, [
    _c(
      "div",
      {
        staticClass: "w-1/2 absolute bottom-0 bg-white shadow-md rounded-t-lg"
      },
      [
        _c("div", { staticClass: "relative pt-2" }, [
          _c(
            "button",
            {
              staticClass: "absolute top-0 right-0 m-1 focus:outline-none",
              on: { click: _vm.close }
            },
            [
              _c("img", {
                staticClass: "w-5 h-5",
                attrs: {
                  src: require("@/assets/images/close.svg"),
                  alt: _vm.$t("general.button_close")
                }
              })
            ]
          ),
          _c("div", { staticClass: "px-6 py-8" }, [
            _vm.user !== null
              ? _c(
                  "div",
                  { staticClass: "flex items-center justify-between" },
                  [
                    _c("div", { staticClass: "flex items-center" }, [
                      _vm.user.avatar !== null
                        ? _c("img", {
                            staticClass: "w-10 h-10 rounded-full",
                            attrs: {
                              src: _vm.user.avatar.small,
                              alt: _vm.$t("general.label_profile_picture")
                            }
                          })
                        : _c("img", {
                            staticClass: "h-10 w-10 rounded-full",
                            attrs: {
                              src: require("@/assets/images/avatar_placeholder.svg"),
                              alt: _vm.$t("general.label_profile_picture")
                            }
                          }),
                      _c(
                        "div",
                        { staticClass: "flex flex-col leading-tight ml-4" },
                        [
                          _c("p", {
                            staticClass: "font-semibold truncate max-w-full",
                            domProps: { textContent: _vm._s(_vm.user.name) }
                          }),
                          _c("p", {
                            staticClass:
                              "text-gray-500 uppercase text-xs truncate max-w-full",
                            domProps: {
                              textContent: _vm._s(
                                _vm.user.teams
                                  .map(function(team) {
                                    return team.name
                                  })
                                  .join("•")
                              )
                            }
                          })
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-col justify-end items-center ml-4 leading-tight"
                      },
                      [
                        _c("p", {
                          staticClass: "text-gray-500 truncate max-w-full",
                          domProps: { textContent: _vm._s(_vm.assignments) }
                        }),
                        _vm.incident
                          ? _c("button", {
                              staticClass:
                                "btn rounded-full max-w-full px-3 py-1 border-0 uppercase\n                focus:outline-none text-xs tracking-wider truncate",
                              class: {
                                "btn-loading": _vm.assigning,
                                "btn-blue": !_vm.isAssigned,
                                "bg-gray-200 text-blue-500":
                                  _vm.isAssigned || !_vm.incident.canAssign,
                                "cursor-not-allowed": !_vm.incident.canAssign
                              },
                              attrs: {
                                disabled:
                                  !_vm.canAssign ||
                                  _vm.assigning ||
                                  _vm.isAssigned
                              },
                              domProps: {
                                textContent: _vm._s(
                                  !_vm.isAssigned
                                    ? _vm.$t("general.button_assign")
                                    : _vm.$t("general.button_assigned")
                                )
                              },
                              on: { click: _vm.assign }
                            })
                          : _vm._e()
                      ]
                    )
                  ]
                )
              : _c("div", { staticClass: "-mt-16" }, [_c("v-loading")], 1)
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }