var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "focus:outline-none",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.clicked($event)
        }
      }
    },
    [
      _c(
        "label",
        { ref: "switch", staticClass: "flex justify-center items-center" },
        [
          _c("switches", {
            attrs: {
              color: "blue",
              theme: "custom",
              "type-bold": "true",
              disabled: _vm.disabled
            },
            model: {
              value: _vm.checked,
              callback: function($$v) {
                _vm.checked = $$v
              },
              expression: "checked"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }