import avatarPlaceholder from '@/assets/images/avatar_placeholder.png';
import EventBus from '@/eventbus';

/**
 * UserMarker class
 * used to display a custom user marker on google maps api
 *
 * @property {Marker} marker
 * @property {User} user
 */

export default class UserMarker {
  user = null;

  marker = null;

  constructor(user, marker) {
    this.user = user;
    this.marker = marker;
  }

  static make(user, markerMap, google) {
    function Marker(latlng, map, args) {
      this.latlng = latlng;
      this.args = args;
      this.setMap(map);
    }

    Marker.prototype = new google.maps.OverlayView();

    Marker.prototype.draw = function draw() {
      const self = this;

      if (!this.div) {
        this.div = document.createElement('div');
        this.div.className = 'relative';

        const innerDiv = document.createElement('div');
        innerDiv.className = 'absolute cursor-pointer p-1 rounded-full bg-white shadow-md z-30';
        innerDiv.style.top = '-18px';
        innerDiv.style.left = '-18px';

        const img = document.createElement('img');
        img.src = this.args.icon;
        img.className = 'w-7 h-7 rounded-full';
        innerDiv.appendChild(img);

        this.div.appendChild(innerDiv);

        google.maps.event.addDomListener(this.div, 'click', () => {
          EventBus.$emit('user_selected', this.args.id);
          google.maps.event.trigger(self, 'click');
        });

        const panes = this.getPanes();
        panes.overlayImage.appendChild(this.div);
      }

      this.setPosition(this.latlng);
    };

    Marker.prototype.setPosition = function setPosition(latlng) {
      this.latlng = latlng;

      if (!this.getProjection()) {
        return;
      }

      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);

      if (point && this.div) {
        this.div.style.left = `${point.x}px`;
        this.div.style.top = `${point.y}px`;
      }
    };

    Marker.prototype.remove = function remove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    };

    Marker.prototype.getPosition = function getPosition() {
      return this.latlng;
    };

    const position = user.lat !== null && user.long !== null
      ? { lat: user.lat, lng: user.long }
      : null;
    const icon = user.avatar !== null ? user.avatar.small : avatarPlaceholder;

    const marker = new Marker(new google.maps.LatLng(position), markerMap, { id: user.id, icon });

    return new UserMarker(user, marker);
  }

  setMap(map) {
    this.marker.setMap(map);
  }

  setPosition(latlng) {
    this.marker.setPosition(latlng);
  }

  updateAvatar(image) {
    if (!this.marker.div) {
      return;
    }

    this.marker.div.getElementsByTagName('img')[0].src = image;
  }
}
