import Morphism from 'morphism';
import moment from 'moment';
import User from './User';
import IncidentType from './IncidentType';
import Link from './Link';
import Broadcast from './Broadcast';
import Victim from './Victim';

/**
 * Incident class
 *
 * @property {?Number} id
 * @property {?String} title
 *
 * @property {?Array<IncidentType>} incidentTypes
 *
 * @property {?Number} lat
 * @property {?Number} long
 * @property {?Number} radius
 *
 * @property {?Date} resolvedAt
 * @property {?Date} createdAt
 * @property {?Date} updatedAt
 * @property {?Date} disappearsAt
 *
 * @property {?Boolean} isVictim
 * @property {?User} user
 * @property {?Array<Victim>} victims
 * @property {?Array<User>} assignedUsers
 *
 * @property {?Broadcast} broadcast
 *
 * @property {?Array<Link>} links
 */

export default class Incident {
  constructor() {
    this.id = null;
    this.title = null;

    this.incidentTypes = [];

    this.lat = null;
    this.long = null;
    this.radius = null;

    this.resolvedAt = null;
    this.createdAt = null;
    this.updatedAt = null;
    this.disappearsAt = null;

    this.isVictim = false;
    this.user = null;
    this.victims = [];
    this.assignedUsers = [];

    this.broadcast = null;

    this.links = [];
  }

  get canLoadMessages() {
    return this.links.some(link => link.rel === 'messages');
  }

  get canCreateMessage() {
    return this.links.some(link => link.rel === 'create_message');
  }

  get resolvedAt() {
    return this._resolvedAt !== null ? moment(this._resolvedAt) : null;
  }

  set resolvedAt(value) {
    this._resolvedAt = value;
  }

  get createdAt() {
    return this._createdAt !== null ? moment(this._createdAt) : null;
  }

  set createdAt(value) {
    this._createdAt = value;
  }

  get updatedAt() {
    return this._updatedAt !== null ? moment(this._updatedAt) : null;
  }

  set updatedAt(value) {
    this._updatedAt = value;
  }

  get disappearsAt() {
    return this._disappearsAt !== null ? moment(this._disappearsAt) : null;
  }

  set disappearsAt(value) {
    this._disappearsAt = value;
  }

  get canAssign() {
    return this.links.some(link => link.rel === 'assign');
  }

  get canResolve() {
    return this.links.some(link => link.rel === 'resolve');
  }

  static fromJson(json) {
    const schema = {
      id: 'id',
      title: 'title',
      incidentTypes: (data) => {
        if (!data.incident_types) {
          return [];
        }

        return IncidentType.fromJson(data.incident_types);
      },
      lat: 'lat',
      long: 'long',
      radius: 'radius',
      resolvedAt: 'resolved_at',
      createdAt: 'created_at',
      updatedAt: 'updated_at',
      disappearsAt: 'disappears_at',
      isVictim: 'is_victim',
      user: (data) => {
        if (!data.user) {
          return null;
        }

        return User.fromJson(data.user);
      },
      victims: (data) => {
        if (!data.victims) {
          return [];
        }

        return Victim.fromJson(data.victims);
      },
      assignedUsers: (data) => {
        if (!data.assigned_users) {
          return [];
        }

        return User.fromJson(data.assigned_users);
      },
      broadcast: (data) => {
        if (!data._broadcast) {
          return null;
        }

        return Broadcast.fromJson(data._broadcast);
      },
      links: (data) => {
        if (!data._links) {
          return [];
        }

        return Link.fromJson(data._links);
      },
    };

    return Morphism(schema, json, Incident);
  }
}
