import Morphism from 'morphism';
import Link from './Link';
import Organization from './Organization';
import Broadcast from './Broadcast';

/**
 * Event class
 *
 * @property {?Number} id
 * @property {?String} name
 * @property {?Boolean} status
 * @property {?Object} focusArea
 * @property {?String} overlayUrl
 * @property {?Number} usersCount
 * @property {?Number} invitedUsersCount
 * @property {?Organization} organization
 * @property {?Broadcast} broadcast
 * @property {?Array<Link>} links
 */
export default class Event {
  constructor() {
    this.id = null;
    this.name = null;
    this.status = null;
    this.focusArea = {};
    this.overlayUrl = null;
    this.usersCount = null;
    this.invitedUsersCount = null;

    this.organization = null;

    this.broadcast = null;
    this.links = [];
  }

  get canView() {
    return this.links.some(link => link.rel === 'self');
  }

  get canCreateTeam() {
    return this.links.some(link => link.rel === 'create_team');
  }

  static fromJson(json) {
    const schema = {
      id: 'id',
      name: 'name',
      status: 'status',
      focusArea: 'focus_area',
      overlayUrl: 'overlay_url',
      usersCount: 'users_count',
      invitedUsersCount: 'invited_users_count',
      organization: (data) => {
        if (!data.organization) {
          return null;
        }

        return Organization.fromJson(data.organization);
      },
      broadcast: (data) => {
        if (!data._broadcast) {
          return null;
        }

        return Broadcast.fromJson(data._broadcast);
      },
      links: (data) => {
        if (!data._links) {
          return [];
        }

        return Link.fromJson(data._links);
      },
    };

    return Morphism(schema, json, Event);
  }
}
