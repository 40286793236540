import axios from 'axios';
import store from '@/store';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

let refreshTokenPromise = false;

function refreshToken() {
  if (!refreshTokenPromise) {
    refreshTokenPromise = store.dispatch('auth/refreshToken');
  }
  return refreshTokenPromise;
}

axios.interceptors.response.use(
  response => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;

    if (status === 401) {
      if (originalRequest.url.includes('/oauth/token')) {
        store.commit('auth/logout');
        throw error;
      }

      return new Promise((resolve, reject) => {
        refreshToken().then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;

          refreshTokenPromise = false;

          resolve(axios.request(originalRequest));
        }).catch(reject);
      });
    }

    return Promise.reject(error);
  },
);
