<template>
  <div v-if="!loading && !error">
    <button
      class="bg-white rounded-full shadow-lg pl-4 pr-1 py-1
        flex items-center justify-center relative cursor-pointer max-w-full
        border border-white focus:outline-none focus:border focus:border-blue-500"
      @click="openPanel">
      <p
        v-text="`${selectedIncidentTypesText} • ${selectedTeamsText}`"
        class="uppercase text-sm text-blue-500"
      ></p>
      <img
        src="@/assets/images/filter.svg"
        :alt="$t('general.label_options')"
        class="h-7 w-7 ml-3"
      >
    </button>
    <filters-panel
      v-if="showPanel"
      @close="closePanel"/>
  </div>
</template>

<script>
  import FiltersPanel from '@/components/panels/FiltersPanel';

  export default {
    name: 'FiltersControl',
    components: {
      FiltersPanel,
    },
    data() {
      return {
        loading: false,
        error: null,
        showPanel: false,
      };
    },
    computed: {
      event() {
        return this.$store.getters['auth/event'];
      },
      usersEnabled() {
        return this.$store.getters['filters/usersEnabled'];
      },
      incidentsEnabled() {
        return this.$store.getters['filters/incidentsEnabled'];
      },
      selectedTeams() {
        return this.$store.getters['filters/selectedTeams'];
      },
      selectedIncidentTypes() {
        return this.$store.getters['filters/selectedIncidentTypes'];
      },
      selectedTeamsText() {
        if (!this.usersEnabled) {
          return this.$t('filters_control.label_no_users');
        }

        if (this.selectedTeams === null) {
          return this.$t('filters_control.label_all_users');
        }

        return this.$t('filters_control.label_teams_count', { amount: this.selectedTeams.length });
      },
      selectedIncidentTypesText() {
        if (!this.incidentsEnabled) {
          return this.$t('filters_control.label_no_incidents');
        }

        if (this.selectedIncidentTypes === null) {
          return this.$t('filters_control.label_all_incidents');
        }

        if (this.selectedIncidentTypes.length === 1) {
          return this.selectedIncidentTypes[0].name;
        }

        return this.$t('filters_control.label_incident_type_count', {
          amount: this.selectedIncidentTypes.length,
        });
      },
    },
    watch: {
      event() {
        this.loadData();
      },
    },
    created() {
      this.loading = true;

      this.loadData();
    },
    beforeDestroy() {
      this.$store.commit('filters/clearTeams');
      this.$store.commit('filters/clearIncidentTypes');
    },
    methods: {
      loadData() {
        Promise.all([
          this.$store.dispatch('filters/loadTeams'),
          this.$store.dispatch('filters/loadIncidentTypes'),
        ]).then(() => {
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          this.error = error;
        });
      },
      openPanel() {
        this.showPanel = true;
      },
      closePanel() {
        this.showPanel = false;
      },
    },
  };
</script>
