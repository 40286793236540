import axios from 'axios';
import moment from 'moment';
import { Validator } from 'vee-validate';
import 'moment/locale/nl';
import i18n from '../../i18n';

export default {
  namespaced: true,
  getters: {
    current: () => i18n.locale,
    supported: () => ['fr', 'en', 'nl'],
    loaded: () => locale => Object.keys(i18n.messages).indexOf(locale) >= 0,
  },
  actions: {
    set(state, locale) {
      i18n.locale = locale;
      axios.defaults.headers.common['Accept-Language'] = locale;
      document.querySelector('html').setAttribute('lang', locale);
      moment.locale(locale);
    },
    update({ dispatch, getters }, locale) {
      const parsedLocale = locale.replace('-', '_').split('_')[0];

      if (getters.supported.find(item => item === parsedLocale) && i18n.locale !== parsedLocale) {
        if (!getters.loaded(parsedLocale)) {
          return dispatch('load', parsedLocale).then(() => Promise.resolve(dispatch('set', parsedLocale)));
        }

        return Promise.resolve(dispatch('set', parsedLocale));
      }

      return Promise.resolve(locale);
    },
    load({ getters }, locale) {
      if (!getters.loaded(locale)) {
        return import(/* webpackChunkName: "lang-[request]" */ `../../assets/lang/${locale}.json`).then((translationModule) => {
          i18n.setLocaleMessage(locale, translationModule.default);

          return import(/* webpackChunkName: "vee-validate-lang-[request]", webpackInclude: /(nl|fr)\.js$/ */ `vee-validate/dist/locale/${locale}`).then((validationModule) => {
            Validator.localize({
              [locale]: validationModule,
            });

            return Promise.resolve(locale);
          });
        });
      }

      return Promise.resolve(locale);
    },
  },
};
