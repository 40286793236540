<template>
  <base-panel
    :overlay="!showTeamsPanel && !showIncidentTypesPanel"
    @overlay="close">
    <incident-types-filter-panel
      v-if="showIncidentTypesPanel"
      @close="closeIncidentTypesPanel"
      @closeAll="close"
    />

    <teams-filter-panel
      v-if="showTeamsPanel"
      @close="closeTeamsPanel"
      @closeAll="close"
    />

    <div class="flex flex-col h-full">
      <div class="flex items-center p-4 border-b border-blue-500">
        <button
          class="focus:outline-none"
          @click="close">
          <img
            src="@/assets/images/close.svg"
            :alt="$t('general.button_close')"
            class="h-7 w-7">
        </button>
        <div class="flex-1 mr-7">
          <p
            v-text="$t('filters_panel.label_filters')"
            class="font-semibold text-center"></p>
        </div>
      </div>
      <div class="flex-grow">
        <div class="flex flex-col">
          <button
            class="focus:outline-none"
            :class="{ 'focus:bg-gray-100': incidentsEnabled }"
            @click="openIncidentTypesPanel"
          >
            <span
              class="flex flex-row justify-between items-center
                ml-4 pr-4 py-3 border-b border-blue-500"
            >
              <span v-text="$t('filters_panel.label_incidents')"></span>
              <span class="flex items-center">
                <v-switch v-model="incidentsEnabled"/>
                <img
                  v-if="incidentsEnabled"
                  src="@/assets/images/ic_chevron_right_blue.svg"
                  :alt="$t('filters_panel.label_incidents')"
                  class="h-4 w-4 ml-1"
                >
                <img
                  v-if="!incidentsEnabled"
                  src="@/assets/images/ic_chevron_right_gray.svg"
                  :alt="$t('filters_panel.label_incidents')"
                  class="h-4 w-4 ml-1"
                >
              </span>
            </span>
          </button>
          <button
            class="focus:outline-none"
            :class="{ 'focus:bg-gray-100': usersEnabled }"
            @click="openTeamsPanel"
          >
            <span
              class="flex flex-row justify-between
                items-center ml-4 pr-4 py-3 border-b border-blue-500"
            >
              <span v-text="$t('filters_panel.label_users')"></span>
              <span class="flex items-center">
                <v-switch v-model="usersEnabled"/>
                <img
                  v-if="usersEnabled"
                  src="@/assets/images/ic_chevron_right_blue.svg"
                  :alt="$t('filters_panel.label_users')"
                  class="h-4 w-4 ml-1"
                >
                <img
                  v-if="!usersEnabled"
                  src="@/assets/images/ic_chevron_right_gray.svg"
                  :alt="$t('filters_panel.label_users')"
                  class="h-4 w-4 ml-1"
                >
              </span>
            </span>
          </button>
        </div>
        <p
          v-text="$t('filters_panel.label_performance')"
          class="m-4 text-gray-500 text-sm"></p>
      </div>
    </div>
  </base-panel>
</template>

<script>
  import IncidentTypesFilterPanel from '@/components/panels/IncidentTypesFilterPanel.vue';
  import TeamsFilterPanel from '@/components/panels/TeamsFilterPanel.vue';
  import BasePanel from '@/components/panels/BasePanel.vue';
  import VSwitch from '@/components/common/VSwitch.vue';

  export default {
    name: 'FiltersPanel',
    components: {
      VSwitch,
      BasePanel,
      TeamsFilterPanel,
      IncidentTypesFilterPanel,
    },
    data() {
      return {
        incidentsEnabled: this.$store.getters['filters/incidentsEnabled'],
        usersEnabled: this.$store.getters['filters/usersEnabled'],
        showIncidentTypesPanel: false,
        showTeamsPanel: false,
      };
    },
    watch: {
      incidentsEnabled() {
        this.save();
      },
      usersEnabled() {
        this.save();
      },
    },
    methods: {
      save() {
        this.$store.commit('filters/updateIncidentsEnabled', this.incidentsEnabled);
        this.$store.commit('filters/updateUsersEnabled', this.usersEnabled);
      },
      openIncidentTypesPanel() {
        if (!this.incidentsEnabled) {
          return;
        }

        this.showIncidentTypesPanel = true;
      },
      closeIncidentTypesPanel() {
        this.showIncidentTypesPanel = false;
      },
      openTeamsPanel() {
        if (!this.usersEnabled) {
          return;
        }

        this.showTeamsPanel = true;
      },
      closeTeamsPanel() {
        this.showTeamsPanel = false;
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
