import axios from 'axios';
import IncidentType from '@/models/IncidentType';

export default class IncidentTypeService {
  /**
   * Get incident types for location
   * @param {Number} lat
   * @param {Number} long
   * @returns {Promise<Array<IncidentType>>}
   */
  static getByLocation(lat, long) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/incident-type?lat=${lat}&long=${long}`)
        .then((response) => {
          resolve(IncidentType.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * Get incident types of event
   * @param {Number} eventId
   * @returns {Promise<Array<IncidentType>>}
   */
  static getByEventId(eventId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/event/${eventId}/incident-types`)
        .then((response) => {
          resolve(IncidentType.fromJson(response.data.data));
        }).catch(reject);
    });
  }
}
