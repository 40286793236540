var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user && _vm.user.completed
    ? _c(
        "div",
        {
          staticClass:
            "flex justify-between items-center p-3 border-b border-blue-500"
        },
        [
          _c(
            "div",
            { staticClass: "flex items-center" },
            [
              _vm.hasEvent
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "flex items-center mr-6 py-2 px-3 rounded-full\n        hover:bg-gray-200 focus:outline-none focus:bg-gray-200",
                      class: { "bg-gray-200": _vm.isIncidentsActive },
                      attrs: { to: { name: "incidents-overview" } }
                    },
                    [
                      _c("img", {
                        staticClass: "h-7 w-7 mr-2",
                        attrs: { src: require("@/assets/images/list.svg") }
                      }),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("vmenu.label_incidents"))
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm.hasEvent
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "flex items-center mr-6 py-2 px-3 rounded-full\n        hover:bg-gray-200 focus:outline-none focus:bg-gray-200",
                      class: { "bg-gray-200": _vm.isTeamsActive },
                      attrs: { to: { name: "teams-overview" } }
                    },
                    [
                      _c("img", {
                        staticClass: "h-7 w-7 mr-2",
                        attrs: { src: require("@/assets/images/team.svg") }
                      }),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("vmenu.label_users"))
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _c(
                "router-link",
                {
                  staticClass:
                    "flex items-center mr-6 py-2 px-3 rounded-full\n        hover:bg-gray-200 focus:outline-none focus:bg-gray-200",
                  class: {
                    "bg-gray-200": _vm.$route.name === "account-detail"
                  },
                  attrs: { to: { name: "account-detail" } }
                },
                [
                  _c("img", {
                    staticClass: "h-7 w-7 mr-3",
                    attrs: {
                      src: require("@/assets/images/avatar_placeholder.svg")
                    }
                  }),
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("vmenu.label_profile"))
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm.user && _vm.user.canCreateIncident
            ? _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "flex items-center py-2 pl-1 pr-3 bg-red-500 text-white rounded-full\n        hover:bg-red-600 focus:outline-none focus:bg-red-600",
                      attrs: { to: { name: "incident-create" } }
                    },
                    [
                      _c("img", {
                        staticClass: "h-7 w-7",
                        attrs: {
                          src: require("@/assets/images/help_white.svg")
                        }
                      }),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("vmenu.button_create_incident")
                          )
                        }
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }