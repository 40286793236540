import Morphism from 'morphism';
import User from './User';
import Link from './Link';

/**
 * Victim class
 *
 * @property {?Number} id
 * @property {?User} user
 *
 * @property {?Array<Link>} links
 */

export default class Victim {
  constructor() {
    this.id = null;
    this.user = null;

    this.links = null;
  }

  static fromJson(json) {
    const schema = {
      id: 'id',
      user: (data) => {
        if (!data.user) {
          return null;
        }

        return User.fromJson(data.user);
      },
      links: (data) => {
        if (!data._links) {
          return [];
        }

        return Link.fromJson(data._links);
      },
    };

    return Morphism(schema, json, Victim);
  }
}
