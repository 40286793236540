var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-panel", { on: { overlay: _vm.closeAll } }, [
    _c(
      "div",
      { staticClass: "flex flex-col h-full" },
      [
        _c(
          "div",
          { staticClass: "flex items-center p-4 border-b border-blue-500" },
          [
            _c(
              "button",
              { staticClass: "focus:outline-none", on: { click: _vm.close } },
              [
                _c("img", {
                  staticClass: "h-7 w-7",
                  attrs: {
                    src: require("@/assets/images/back.svg"),
                    alt: _vm.$t("general.button_back")
                  }
                })
              ]
            ),
            _c("div", { staticClass: "flex-1 mr-7" }, [
              _c("p", {
                staticClass: "font-semibold text-center",
                domProps: {
                  textContent: _vm._s(_vm.$t("teams_filter_panel.label_teams"))
                }
              })
            ])
          ]
        ),
        _c("v-scrollable", { staticClass: "flex-grow" }, [
          _c(
            "ul",
            _vm._l(_vm.teams, function(team) {
              return _c(
                "li",
                {
                  key: team.name,
                  staticClass:
                    "flex flex-row justify-between items-center\n            ml-4 pr-4 py-3 border-b border-blue-500"
                },
                [
                  _c(
                    "label",
                    { staticClass: "flex justify-between w-full" },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c("img", {
                          staticClass: "w-6 h-6",
                          attrs: {
                            src: require("@/assets/images/avatar_placeholder.svg"),
                            alt: _vm.$t("general.label_profile_picture")
                          }
                        }),
                        _c("p", {
                          staticClass: "uppercase ml-2",
                          domProps: { textContent: _vm._s(team.name) }
                        })
                      ]),
                      _c("v-switch", {
                        attrs: {
                          value: _vm.selectedTeamIds.indexOf(team.id) >= 0
                        },
                        on: {
                          input: function(checked) {
                            return _vm.teamToggled(team, checked)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            0
          ),
          _c("p", {
            staticClass: "m-4 text-gray-500 text-sm",
            domProps: {
              textContent: _vm._s(
                _vm.$t("teams_filter_panel.label_performance")
              )
            }
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }