let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;

const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

function initGoogleMaps() {
  if (initialized) {
    return initPromise;
  }

  const API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API;
  const CALLBACK_NAME = 'gMapsLoaded';

  initialized = true;
  window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=${CALLBACK_NAME}&libraries=geometry`;

  script.onerror = rejectInitPromise;
  document.querySelector('head').appendChild(script);

  return initPromise;
}

function getNormalizedCoord(coord, zoom) {
  const { y } = coord;
  let { x } = coord;
  // eslint-disable-next-line
  const tileRange = 1 << zoom;

  if (y < 0 || y >= tileRange) {
    return null;
  }

  if (x < 0 || x >= tileRange) {
    x = ((x % tileRange) + tileRange) % tileRange;
  }

  return { x, y };
}

export default {
  google: null,
  map: null,
  init(element, options) {
    return new Promise((resolve) => {
      initGoogleMaps().then((google) => {
        this.google = google;
        this.map = new google.maps.Map(element, options);
        resolve();
      });
    });
  },
  addMapOverlay(overlayUrl) {
    const eventMapType = new this.google.maps.ImageMapType({
      getTileUrl(coord, zoom) {
        const normalizedCoord = getNormalizedCoord(coord, zoom);

        if (!normalizedCoord) {
          return null;
        }

        return `${overlayUrl}${zoom}/${normalizedCoord.x}/${
          normalizedCoord.y
        }.png`;
      },
      tileSize: new this.google.maps.Size(256, 256),
      maxZoom: 20,
      minZoom: 0,
      name: 'Event',
    });

    this.map.overlayMapTypes.push(eventMapType);
  },
};
