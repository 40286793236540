<template>
  <base-panel @overlay="closeAll">
    <div class="flex flex-col h-full">
      <div class="flex items-center p-4 border-b border-blue-500">
        <button
          class="focus:outline-none"
          @click="close">
          <img
            src="@/assets/images/back.svg"
            :alt="$t('general.button_back')"
            class="h-7 w-7">
        </button>
        <div class="flex-1 mr-7">
          <p
            v-text="$t('teams_filter_panel.label_teams')"
            class="font-semibold text-center"
          ></p>
        </div>
      </div>
      <v-scrollable class="flex-grow">
        <ul>
          <li
            v-for="team in teams"
            :key="team.name"
            class="flex flex-row justify-between items-center
              ml-4 pr-4 py-3 border-b border-blue-500">
            <label class="flex justify-between w-full">
              <div class="flex">
                <img
                  src="@/assets/images/avatar_placeholder.svg"
                  :alt="$t('general.label_profile_picture')"
                  class="w-6 h-6"
                >
                <p
                  v-text="team.name"
                  class="uppercase ml-2"></p>
              </div>
              <v-switch
                :value="selectedTeamIds.indexOf(team.id) >= 0"
                @input="(checked) => teamToggled(team, checked)"/>
            </label>
          </li>
        </ul>
        <p
          v-text="$t('teams_filter_panel.label_performance')"
          class="m-4 text-gray-500 text-sm">
        </p>
      </v-scrollable>
    </div>
  </base-panel>
</template>

<script>
  import BasePanel from '@/components/panels/BasePanel.vue';
  import VScrollable from '@/components/common/VScrollable.vue';
  import VSwitch from '@/components/common/VSwitch.vue';

  export default {
    name: 'TeamsFilterPanel',
    components: {
      BasePanel,
      VScrollable,
      VSwitch,
    },
    data() {
      return {
        selectedTeamIds: [],
      };
    },
    computed: {
      teams() {
        return this.$store.getters['filters/teams'];
      },
      selectedTeams() {
        const selectedIds = this.selectedTeamIds;

        return this.$store.getters['filters/teams'].filter(team => selectedIds.indexOf(team.id) >= 0);
      },
    },
    created() {
      let selectedTeamIds = this.$store.getters['filters/selectedTeamIds'];

      if (selectedTeamIds === null) {
        selectedTeamIds = this.teams.map(team => team.id);
      }

      this.selectedTeamIds = [...selectedTeamIds];
    },
    methods: {
      teamToggled(team, checked) {
        const index = this.selectedTeamIds.indexOf(team.id);

        if (!checked && index >= 0) {
          this.selectedTeamIds.splice(index, 1);
        }

        if (checked && index < 0) {
          this.selectedTeamIds.push(team.id);
        }

        this.save();
      },
      save() {
        if (this.teams.length !== this.selectedTeams.length) {
          this.$store.commit('filters/setSelectedTeams', this.selectedTeams);
        } else {
          this.$store.commit('filters/setSelectedTeams', null);
        }
      },
      close() {
        this.$emit('close');
      },
      closeAll() {
        this.$emit('closeAll');
      },
    },
  };
</script>
