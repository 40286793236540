var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "columns content-around items-stretch m-0 min-w-0 overflow-auto"
    },
    [
      _c(
        "div",
        { staticClass: "column flex flex-col items-stretch p-0 h-full" },
        [
          _c("div", { staticClass: "flex h-full relative flex-1" }, [
            _c(
              "div",
              { staticClass: "flex flex-col h-full w-full absolute" },
              [_vm._t("default")],
              2
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }