<template>
  <div
    id="app"
    class="flex flex-col h-screen font-sans">
    <v-connectivity
      v-if="authenticated"
      class="w-full"/>
    <v-menu
      v-if="authenticated && !$route.meta.guest"
      class="w-full"/>
    <div class="flex flex-1">
      <div class="w-1/4 max-h-full border-r border-blue-500 shadow-lg z-30">
        <router-view class="h-full"/>
      </div>
      <v-map class="w-3/4"></v-map>
    </div>
  </div>
</template>

<script>
  import VConnectivity from '@/components/common/VConnectivity';
  import VMenu from '@/components/common/VMenu.vue';
  import VMap from '@/components/common/VMap.vue';
  import Echo from 'laravel-echo';
  import 'pusher-js';
  import EventBus from '@/eventbus';
  import Incident from '@/models/Incident';
  import Message from '@/models/Message';
  import User from '@/models/User';
  import AppIcon from '@/assets/images/app_icon.png';

  export default {
    components: {
      VMap,
      VConnectivity,
      VMenu,
    },
    data() {
      return {
        echo: null,
      };
    },
    computed: {
      event() {
        return this.$store.getters['auth/event'];
      },
      accessToken() {
        return this.$store.getters['auth/accessToken'];
      },
      incident() {
        return this.$store.getters['incident/item'];
      },
      authenticated() {
        return this.$store.getters['auth/check'];
      },
    },
    watch: {
      event(newEvent, oldEvent) {
        if (
          oldEvent
          && (!this.event || this.event.id !== oldEvent.id)
          && this.echo
        ) {
          this.echo.leaveChannel(oldEvent.broadcast.channel);
          this.echo.leaveChannel(oldEvent.broadcast.presenceChannel);
        }

        if (
          !this.event
          || this.event.broadcast === null
          || (oldEvent && this.event.id === oldEvent.id)
        ) {
          return;
        }

        this.connect();
        this.listenForEvent();
      },
      accessToken() {
        if (!this.echo) {
          return;
        }

        this.echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${
          this.accessToken
        }`;
      },
      incident(newIncident, oldIncident) {
        if (
          oldIncident
          && (!this.incident || this.incident.id !== oldIncident.id)
          && this.echo
        ) {
          this.echo.leaveChannel(oldIncident.broadcast.channel);
          this.echo.leaveChannel(oldIncident.broadcast.presenceChannel);
        }

        if (!this.incident || this.incident.broadcast === null) {
          return;
        }

        this.connect();
        this.listenForIncident();
      },
      authenticated() {
        if (this.authenticated) {
          return;
        }

        this.disconnect();
      },
    },
    mounted() {
      EventBus.$on('incident_selected', (incidentId) => {
        this.$router.push({
          name: 'incident-detail',
          params: { id: `${incidentId}` },
        });
      });

      EventBus.$on('user_selected', (userId) => {
        if (['incident-detail', 'incident-assign'].includes(this.$route.name)) {
          return;
        }

        this.$router.push({ name: 'user-detail', params: { id: `${userId}` } });
      });
    },
    beforeDestroy() {
      this.disconnect();
    },
    methods: {
      connect() {
        if (this.echo) {
          return;
        }

        this.echo = new Echo({
          broadcaster: 'pusher',
          key: process.env.VUE_APP_PUSHER_API_KEY,
          wsHost: process.env.VUE_APP_PUSHER_HOST,
          wssPort: process.env.VUE_APP_PUSHER_PORT,
          cluster: process.env.VUE_APP_PUSHER_CLUSTER,
          encrypted: true,
          disableState: true,
          authEndpoint: `${process.env.VUE_APP_BASE_URL}/broadcasting/auth`,
          auth: {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${this.accessToken}`,
            },
          },
        });
      },
      listenForEvent() {
        if (!this.echo) {
          return;
        }

        this.echo.channel(this.event.broadcast.presenceChannel);

        this.echo
          .channel(this.event.broadcast.channel)
          .listen(
            `.${this.event.broadcast.events.user_duty_started}`,
            this.userDutyStarted,
          )
          .listen(
            `.${this.event.broadcast.events.user_location_updated}`,
            this.userLocationUpdated,
          )
          .listen(
            `.${this.event.broadcast.events.user_duty_ended}`,
            this.userDutyEnded,
          )
          .listen(
            `.${this.event.broadcast.events.incident_created}`,
            this.incidentCreated,
          )
          .listen(
            `.${this.event.broadcast.events.incident_resolved}`,
            this.incidentResolved,
          );
      },
      userDutyStarted(user) {
        EventBus.$emit('user_duty_started', user);

        this.$store.commit('user/updateItem', User.fromJson(user));
        this.$store.commit('team/updateUserOfItem', User.fromJson(user));
      },
      userLocationUpdated(user) {
        EventBus.$emit('user_location_updated', user);

        this.$store.commit('user/updateItem', User.fromJson(user));
      },
      userDutyEnded(user) {
        EventBus.$emit('user_duty_ended', user);

        this.$store.commit('user/updateItem', User.fromJson(user));
        this.$store.commit('team/updateUserOfItem', User.fromJson(user));
      },
      incidentCreated(incident) {
        EventBus.$emit('incident_created', Incident.fromJson(incident));

        this.$store.commit('incident/addToList', Incident.fromJson(incident));

        this.showIncidentCreatedNotification(Incident.fromJson(incident));
      },
      incidentResolved(incident) {
        EventBus.$emit('incident_resolved', Incident.fromJson(incident));

        this.$store.commit('incident/updateInList', Incident.fromJson(incident));

        this.showIncidentResolvedNotification(Incident.fromJson(incident));
      },
      listenForIncident() {
        if (!this.echo) {
          return;
        }

        this.echo.channel(this.incident.broadcast.presenceChannel);

        this.echo
          .channel(this.incident.broadcast.channel)
          .listen(`.${this.incident.broadcast.events.user_assigned}`,
                  this.userAssigned)
          .listen(`.${this.incident.broadcast.events.message_created}`,
                  this.messageCreated)
          .listen(`.${this.incident.broadcast.events.resolved}`,
                  this.currentIncidentResolved);
      },
      userAssigned(user) {
        this.$store.commit('incident/addAssignedUserToItem', User.fromJson(user));
      },
      messageCreated(message) {
        this.$store.commit('message/addToList', Message.fromJson(message));
      },
      currentIncidentResolved(incident) {
        this.$store.commit('incident/setItem', Incident.fromJson(incident));
      },
      disconnect() {
        if (!this.echo) {
          return;
        }

        this.echo.disconnect();
        this.echo = null;
      },
      showIncidentCreatedNotification(incident) {
        if (!('Notification' in window) || Notification.permission !== 'granted') {
          return;
        }

        if (this.incident && this.incident.id === incident.id) {
          return;
        }

        const name = incident.title
          || (incident.incidentTypes.length > 0 ? incident.incidentTypes[0].name : undefined)
          || this.$t('general.label_unknown');

        this.$notification.show(
          this.$t('push_notification.label_incident_created_title'),
          {
            body: this.$t('push_notification.label_incident_created_body', { name }),
            icon: AppIcon,
          },
          {
            onclick: () => this.$router.push({
              name: 'incident-detail',
              params: { id: `${incident.id}` },
            }),
          },
        );
      },
      showIncidentResolvedNotification(incident) {
        if (!('Notification' in window) || Notification.permission !== 'granted') {
          return;
        }

        if (this.incident && this.incident.id === incident.id) {
          return;
        }

        const name = incident.title
          || (incident.incidentTypes.length > 0 ? incident.incidentTypes[0].name : undefined)
          || this.$t('general.label_unknown');

        this.$notification.show(
          this.$t('push_notification.label_incident_resolved_title'),
          {
            body: this.$t('push_notification.label_incident_resolved_body', { name }),
            icon: AppIcon,
          },
          {
            onclick: () => this.$router.push({
              name: 'incident-detail',
              params: { id: `${incident.id}` },
            }),
          },
        );
      },
    },
  };
</script>

<style lang="scss">
  @import './assets/scss/main.scss';
</style>
