<template>
  <base-panel @overlay="closeAll">
    <div class="flex flex-col h-full">
      <div class="flex items-center p-4 border-b border-blue-500">
        <button
          class="focus:outline-none"
          @click="close">
          <img
            src="@/assets/images/back.svg"
            :alt="$t('general.button_back')"
            class="h-7 w-7">
        </button>
        <div class="flex-1 mr-7">
          <p
            v-text="$t('incident_types_filter_panel.label_incident_types')"
            class="font-semibold text-center"
          ></p>
        </div>
      </div>
      <v-scrollable class="flex-grow">
        <ul>
          <li
            v-for="incidentType in incidentTypes"
            :key="incidentType.name"
            class="flex flex-row justify-between items-center
              ml-4 pr-4 py-3 border-b border-blue-500">
            <label class="flex justify-between w-full">
              <div class="flex items-center">
                <span
                  class="flex-none h-7 w-7 rounded-full"
                  :style="{'background-color': incidentType.color}">
                </span>
                <p
                  v-text="incidentType.name"
                  class="uppercase ml-2"></p>
              </div>
              <v-switch
                :value="selectedIncidentTypeIds.indexOf(incidentType.id) >= 0"
                @input="(checked) => incidentTypeToggled(incidentType, checked)"/>
            </label>
          </li>
        </ul>
        <p
          v-text="$t('incident_types_filter_panel.label_performance')"
          class="m-4 text-gray-500 text-sm">
        </p>
      </v-scrollable>
    </div>
  </base-panel>
</template>

<script>
  import BasePanel from '@/components/panels/BasePanel.vue';
  import VScrollable from '@/components/common/VScrollable.vue';
  import VSwitch from '@/components/common/VSwitch.vue';

  export default {
    name: 'IncidentTypesFilterPanel',
    components: {
      BasePanel,
      VScrollable,
      VSwitch,
    },
    data() {
      return {
        selectedIncidentTypeIds: [],
      };
    },
    computed: {
      incidentTypes() {
        return this.$store.getters['filters/incidentTypes'];
      },
      selectedIncidentTypes() {
        const selectedIds = this.selectedIncidentTypeIds;

        return this.$store.getters['filters/incidentTypes'].filter(type => selectedIds.indexOf(type.id) >= 0);
      },
    },
    created() {
      let selectedIncidentTypeIds = this.$store.getters['filters/selectedIncidentTypeIds'];

      if (selectedIncidentTypeIds === null) {
        selectedIncidentTypeIds = this.incidentTypes.map(type => type.id);
      }

      this.selectedIncidentTypeIds = [...selectedIncidentTypeIds];
    },
    methods: {
      incidentTypeToggled(incidentType, checked) {
        const index = this.selectedIncidentTypeIds.indexOf(incidentType.id);

        if (!checked && index >= 0) {
          this.selectedIncidentTypeIds.splice(index, 1);
        }

        if (checked && index < 0) {
          this.selectedIncidentTypeIds.push(incidentType.id);
        }

        this.save();
      },
      save() {
        if (this.incidentTypes.length !== this.selectedIncidentTypes.length) {
          this.$store.commit('filters/setSelectedIncidentTypes', this.selectedIncidentTypes);
        } else {
          this.$store.commit('filters/setSelectedIncidentTypes', null);
        }
      },
      close() {
        this.$emit('close');
      },
      closeAll() {
        this.$emit('closeAll');
      },
    },
  };
</script>
