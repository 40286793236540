import axios from 'axios';
import Incident from '@/models/Incident';

export default class IncidentService {
  /**
   * Get incidents within bounding box
   * @param {Object} boundingBox
   * @param {null|Array} incidentTypeIds
   * @returns {Promise<Array<Incident>>}
   */
  static getByBoundingBox({ topLeft, bottomRight }, incidentTypeIds = null) {
    let querystring = `bounding_box[top_left]=${topLeft.lat},${topLeft.lng}&bounding_box[bottom_right]=${bottomRight.lat},${bottomRight.lng}`;

    if (incidentTypeIds !== null) {
      querystring = `${querystring}&incident_type_ids=${incidentTypeIds.join(',')}`;
    }

    return new Promise((resolve, reject) => {
      axios.get(`/api/incident?${querystring}`)
        .then((response) => {
          resolve(Incident.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * Get incident by id
   * @param {Number} id
   * @returns {Promise<Incident>}
   */
  static getById(id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/incident/${id}`)
        .then((response) => {
          resolve(Incident.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * Get incidents for event by page number
   * @param {Number} eventId
   * @param {Number} pageNumber
   * @param {?String} searchValue
   * @returns {Promise<{Array<Incident>, Number?}>}
   */
  static getByEvent(eventId, pageNumber = 1, searchValue = null) {
    return new Promise((resolve, reject) => {
      let querystring = `page=${pageNumber}`;

      if (searchValue) {
        querystring = `${querystring}&q=${searchValue}`;
      }

      axios.get(`/api/event/${eventId}/incidents?${querystring}`)
        .then((response) => {
          resolve({
            incidents: Incident.fromJson(response.data.data),
            nextPage: (response.data.meta.last_page >= pageNumber + 1)
              ? pageNumber + 1 : null,
          });
        }).catch(reject);
    });
  }

  /**
  * add incident
  * @param {Incident} incident
  * @returns {Promise<Incident>}
  */
  static create(incident) {
    return new Promise((resolve, reject) => {
      axios.post('/api/incident', {
        title: incident.title,
        lat: incident.lat,
        long: incident.long,
        radius: incident.radius,
        incident_types: incident.incidentTypes.map(incidentType => incidentType.id),
        is_victim: incident.isVictim,
      })
        .then((response) => {
          resolve(Incident.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * Assign user to incident
   * @param {User} user
   * @param {Incident} incident
   * @returns {Promise}
   */
  static assignUser(user, incident) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/incident/${incident.id}/assign`, { user_id: user.id })
        .then((response) => {
          resolve(Incident.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * Resolve incident
   * @param {Incident} incident
   * @returns {Promise<Incident>}
   */
  static resolve(incident) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/incident/${incident.id}/resolve`)
        .then((response) => {
          resolve(Incident.fromJson(response.data.data));
        }).catch(reject);
    });
  }
}
