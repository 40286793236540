<template>
  <div
    class="flex justify-between items-center p-3 border-b border-blue-500"
    v-if="user && user.completed"
  >
    <div class="flex items-center">
      <router-link
        v-if="hasEvent"
        :to="{ name: 'incidents-overview' }"
        class="flex items-center mr-6 py-2 px-3 rounded-full
          hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
        :class="{ 'bg-gray-200': isIncidentsActive }"
      >
        <img
          src="@/assets/images/list.svg"
          class="h-7 w-7 mr-2"
        />
        <span v-text="$t('vmenu.label_incidents')"></span>
      </router-link>
      <router-link
        v-if="hasEvent"
        :to="{ name: 'teams-overview' }"
        class="flex items-center mr-6 py-2 px-3 rounded-full
          hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
        :class="{ 'bg-gray-200': isTeamsActive }"
      >
        <img
          src="@/assets/images/team.svg"
          class="h-7 w-7 mr-2"
        />
        <span v-text="$t('vmenu.label_users')"></span>
      </router-link>
      <router-link
        :to="{ name: 'account-detail' }"
        class="flex items-center mr-6 py-2 px-3 rounded-full
          hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
        :class="{ 'bg-gray-200': $route.name === 'account-detail' }"
      >
        <img
          src="@/assets/images/avatar_placeholder.svg"
          class="h-7 w-7 mr-3"
        />
        <span v-text="$t('vmenu.label_profile')"></span>
      </router-link>
    </div>
    <div v-if="user && user.canCreateIncident">
      <router-link
        :to="{ name: 'incident-create' }"
        class="flex items-center py-2 pl-1 pr-3 bg-red-500 text-white rounded-full
          hover:bg-red-600 focus:outline-none focus:bg-red-600"
      >
        <img
          src="@/assets/images/help_white.svg"
          class="h-7 w-7"
        />
        <span v-text="$t('vmenu.button_create_incident')"></span>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VMenu',
    computed: {
      user() {
        return this.$store.getters['auth/user'];
      },
      hasEvent() {
        return this.$store.getters['auth/eventCheck'];
      },
      isIncidentsActive() {
        const routes = [
          'incidents-overview',
          'incident-detail',
          'incident-assign',
          'incident-create',
        ];

        return routes.indexOf(this.$route.name) >= 0;
      },
      isTeamsActive() {
        const routes = [
          'teams-overview',
          'team-detail',
          'team-create',
          'user-detail',
          'invite',
          'invited-users-overview',
        ];

        return routes.indexOf(this.$route.name) >= 0;
      },
    },
  };
</script>
