import EventBus from '@/eventbus';

/**
 * IncidentMarker class
 * used to display custom incident marker on google maps api
 *
 * @property {Marker} marker
 * @property {Incident} incident
 */

export default class IncidentMarker {
  incident = null;

  marker = null;

  constructor(incident, marker) {
    this.incident = incident;
    this.marker = marker;
  }

  static make(incident, markerMap, google) {
    function Marker(latlng, map, args) {
      this.latlng = latlng;
      this.args = args;
      this.setMap(map);
    }

    Marker.prototype = new google.maps.OverlayView();

    Marker.prototype.draw = function draw() {
      if (!this.div) {
        this.div = document.createElement('div');
        this.div.className = 'relative';

        const innerDiv = document.createElement('div');
        innerDiv.className = 'absolute w-8 h-8 z-40 cursor-pointer';
        innerDiv.style.top = '-32px';
        innerDiv.style.left = '-16px';
        innerDiv.innerHTML = `
          <svg viewBox="0 0 34 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <circle id="path-1" cx="17" cy="16" r="11"></circle>
            </defs>
            <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="pin">
                    <ellipse id="Oval" fill-opacity="0.1" fill="#000000" cx="17" cy="37" rx="6" ry="3"></ellipse>
                    <path d="M14.1299276,28.6821201 C8.3311775,27.3754173 4,22.193509 4,16 C4,8.82029825 9.82029825,3 17,3 C24.1797017,3 30,8.82029825 30,16 C30,22.193509 25.6688225,27.3754173 19.8700724,28.6821201 L18.526287,35.7369933 C18.3867022,36.4698136 17.7459957,37 17,37 C16.2540043,37 15.6132978,36.4698136 15.473713,35.7369933 L14.1299276,28.6821201 Z" id="Path" stroke="#B2B2B2" stroke-width="0.5" fill="#FFFFFF" stroke-linecap="square"></path>
                    <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                    </mask>
                    <use id="incident_type" fill="${this.args.color}" xlink:href="#path-1"></use>
                </g>
            </g>
          </svg>`;

        this.div.appendChild(innerDiv);

        google.maps.event.addDomListener(this.div, 'click', () => {
          EventBus.$emit('incident_selected', this.args.id);
        });

        const panes = this.getPanes();
        panes.overlayImage.appendChild(this.div);
      }

      this.setPosition(this.latlng);
    };

    Marker.prototype.setPosition = function setPosition(latlng) {
      this.latlng = latlng;

      if (!this.getProjection()) {
        return;
      }

      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);

      if (point && this.div !== null) {
        this.div.style.left = `${point.x}px`;
        this.div.style.top = `${point.y}px`;
      }
    };

    Marker.prototype.remove = function remove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    };

    Marker.prototype.getPosition = function getPosition() {
      return this.latlng;
    };

    const position = { lat: incident.lat, lng: incident.long };

    let color = '#7BA8DA';

    if (!incident.resolvedAt) {
      color = incident.incidentTypes.length > 0 ? incident.incidentTypes[0].color : '#7BA8DA';
    } else {
      color = '#B2B2B2';
    }

    const args = { id: incident.id, color };

    const marker = new Marker(new google.maps.LatLng(position), markerMap, args);

    return new IncidentMarker(incident, marker);
  }

  setMap(map) {
    this.marker.setMap(map);
  }

  setPosition(latlng) {
    this.marker.setPosition(latlng);
  }

  setResolved() {
    if (!this.marker.div) {
      return;
    }

    this.marker.div.querySelector('#incident_type').setAttribute('fill', '#B2B2B2');
  }
}
