import TeamService from '@/services/TeamService';

export default {
  namespaced: true,
  state: {
    list: null,
    item: null,
  },
  getters: {
    list: state => state.list,
    item: state => state.item,
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    setItem(state, item) {
      state.item = item;
    },
    clearList(state) {
      state.list = null;
    },
    clearItem(state) {
      state.item = null;
    },
    clearAll(state) {
      state.list = null;
      state.item = null;
    },
    updateUserOfItem(state, updatedUser) {
      if (state.item === null) {
        return;
      }

      const user = state.item.users.find(item => item.id === updatedUser.id);

      if (!user) {
        return;
      }

      user.firstname = updatedUser.firstname;
      user.lastname = updatedUser.lastname;
      user.birthdate = updatedUser.birthdate;
      user.phone = updatedUser.phone;
      user.email = updatedUser.email;
      user.avatar = updatedUser.avatar;

      if (updatedUser.onDuty && !user.onDuty) {
        user.distance = null;
      }

      user.onDuty = updatedUser.onDuty;
      user.lat = updatedUser.lat;
      user.long = updatedUser.long;
      user.phoneVerified = updatedUser.phoneVerified;
      user.emailVerified = updatedUser.emailVerified;

      if (updatedUser.assignedIncidentsCount) {
        user.assignedIncidentsCount = updatedUser.assignedIncidentsCount;
      }
    },
  },
  actions: {
    loadByEventId({ commit }, eventId) {
      return new Promise((resolve, reject) => {
        TeamService.getByEventId(eventId)
          .then((teams) => {
            commit('setList', teams);
            resolve();
          }).catch(reject);
      });
    },
    create(context, { event, team }) {
      return new Promise((resolve, reject) => {
        TeamService.create(event, team)
          .then(resolve)
          .catch(reject);
      });
    },
    loadById({ commit }, id) {
      return new Promise((resolve, reject) => {
        TeamService.getById(id)
          .then((team) => {
            commit('setItem', team);
            resolve();
          }).catch(reject);
      });
    },
    loadByIdAndCoordinates({ commit }, { id, lat, long }) {
      return new Promise((resolve, reject) => {
        TeamService.getByIdAndCoordinates(id, { lat, long })
          .then((team) => {
            commit('setItem', team);
            resolve();
          }).catch(reject);
      });
    },
  },
};
