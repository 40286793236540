import axios from 'axios';
import Team from '@/models/Team';

export default class TeamService {
  /**
   * Get all team for event
   * @param {Number} eventId
   * @returns {Promise<Array<Team>>}
   */
  static getByEventId(eventId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/event/${eventId}/teams`)
        .then((response) => {
          resolve(Team.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
  * Get team by id
  * @param {Number} id
  * @returns {Promise<Team>}
  */
  static getById(id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/team/${id}`)
        .then((response) => {
          resolve(Team.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
   * get team by id (order users by distance of location)
   * @param {Number} id
   * @param {Object} location
   * @returns {Promise<Team>}
   */
  static getByIdAndCoordinates(id, { lat, long }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/team/${id}?lat=${lat}&long=${long}`)
        .then((response) => {
          resolve(Team.fromJson(response.data.data));
        }).catch(reject);
    });
  }

  /**
  * Create new team within event
  * @param {Event} event
  * @param {Team} team
  */
  static create(event, team) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/event/${event.id}/teams`, {
        name: team.name,
      }).then((response) => {
        resolve(Team.fromJson(response.data.data));
      }).catch(reject);
    });
  }

  /**
   * Remove user from team
   * @param {Team} team
   * @param {User} user
   * @returns {Promise<Team>}
   */
  static removeUser(team, user) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/team/${team.id}/users/${user.id}`)
        .then((response) => {
          resolve(Team.fromJson(response.data.data));
        }).catch(reject);
    });
  }
}
