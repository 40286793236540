import Morphism from 'morphism';

/**
 * Link class
 * @property {?String} rel
 * @property {?String} type
 * @property {?String} href
 */

export default class Link {
  constructor() {
    this.rel = null;
    this.type = null;
    this.href = null;
  }

  static fromJson(json) {
    const schema = {
      rel: 'rel',
      type: 'type',
      href: 'href',
    };

    return Morphism(schema, json, Link);
  }
}
