import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import AuthService from '@/services/AuthService';

export default {
  namespaced: true,
  state: {
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    user: null,
    eventId: parseInt(localStorage.getItem('eventId'), 10),
    event: null,
  },
  getters: {
    user: state => state.user,
    check: state => state.accessToken !== null && state.refreshToken !== null,
    accessToken: state => state.accessToken,
    event: state => state.event,
    eventCheck: state => state.eventId !== null,
  },
  mutations: {
    setTokens(state, { accessToken, refreshToken }) {
      localStorage.setItem('accessToken', accessToken);
      state.accessToken = accessToken;

      localStorage.setItem('refreshToken', refreshToken);
      state.refreshToken = refreshToken;

      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    },
    setUser(state, user) {
      state.user = user;
    },
    setEvent(state, event) {
      state.event = event;

      localStorage.setItem('eventId', event.id);
      state.eventId = event.id;
    },
    clearEvent(state) {
      state.event = null;

      localStorage.removeItem('eventId');
      state.eventId = null;
    },
    logout(state) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('eventId');

      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      state.eventId = null;
      state.event = null;

      axios.defaults.headers.common.Authorization = null;
    },
  },
  actions: {
    init({ state }) {
      if (state.accessToken != null) {
        axios.defaults.headers.common.Authorization = `Bearer ${state.accessToken}`;
      }
    },
    load({ state, commit, dispatch }) {
      dispatch('init');

      return new Promise((resolve, reject) => {
        AuthService.getUser()
          .then((user) => {
            commit('setUser', user);

            let event = null;

            if (state.eventId !== null) {
              event = state.user.events.find(item => item.id === state.eventId && item.canView);
            }

            if (!event) {
              commit('clearEvent');
              commit('filters/clearAll', null, { root: true });

              event = state.user.events.find(item => item.canView);
            }

            if (event) {
              commit('setEvent', cloneDeep(event));
            }

            return resolve();
          }).catch(reject);
      });
    },
    login({ commit, dispatch }, { username, password }) {
      return new Promise((resolve, reject) => {
        AuthService.authenticate(username, password)
          .then(({ accessToken, refreshToken }) => {
            commit('setTokens', {
              accessToken,
              refreshToken,
            });
          })
          .then(() => dispatch('load'))
          .then(resolve)
          .catch(reject);
      });
    },
    loginAsApplication({ commit }) {
      return new Promise((resolve, reject) => {
        AuthService.authenticateAsApplication()
          .then((accessToken) => {
            commit('setTokens', { accessToken, refreshToken: null });
            resolve();
          }).catch(reject);
      });
    },
    updateUser({ commit }, user) {
      return new Promise((resolve, reject) => {
        Promise.all([
          AuthService.updateUser(user),
          ...(user.avatar instanceof File ? [AuthService.updateUserAvatar(user.avatar)] : []),
        ]).then((results) => {
          commit('setUser', results[results.length - 1]);
          resolve();
        }).catch(reject);
      });
    },
    updatePassword({ commit }, user) {
      return new Promise((resolve, reject) => {
        AuthService.updateUserPassword(user)
          .then((updatedUser) => {
            commit('setUser', updatedUser);
            resolve();
          }).catch(reject);
      });
    },
    logout({ dispatch, commit }) {
      return new Promise((resolve) => {
        AuthService.logout()
          .then(() => {
            commit('logout');
            dispatch('clearAll', null, { root: true });
            resolve();
          })
          .catch(() => {
            commit('logout');
            dispatch('clearAll', null, { root: true });
            resolve();
          });
      });
    },
    resetPassword({ dispatch }, email) {
      return new Promise((resolve, reject) => {
        dispatch('loginAsApplication')
          .then(() => AuthService.resetPassword(email))
          .then(resolve)
          .catch(reject);
      });
    },
    refreshToken({ commit, state }) {
      return new Promise((resolve, reject) => AuthService.refreshToken(state.refreshToken)
        .then(({ accessToken, refreshToken }) => {
          commit('setTokens', {
            accessToken,
            refreshToken,
          });

          resolve(accessToken);
        }).catch(reject));
    },
  },
};
