import IncidentTypeService from '@/services/IncidentTypeService';

export default {
  namespaced: true,
  state: {
    list: null,
  },
  getters: {
    list: state => state.list,
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    clearAll(state) {
      state.list = null;
    },
  },
  actions: {
    loadByLocation({ commit }, { lat, long }) {
      return new Promise((resolve, reject) => {
        IncidentTypeService.getByLocation(lat, long)
          .then((incidentTypes) => {
            commit('setList', incidentTypes);
            resolve();
          }).catch(reject);
      });
    },
  },
};
