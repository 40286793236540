var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading && !_vm.error
    ? _c(
        "div",
        [
          _c(
            "button",
            {
              staticClass:
                "bg-white rounded-full shadow-lg pl-4 pr-1 py-1\n      flex items-center justify-center relative cursor-pointer max-w-full\n      border border-white focus:outline-none focus:border focus:border-blue-500",
              on: { click: _vm.openPanel }
            },
            [
              _c("p", {
                staticClass: "uppercase text-sm text-blue-500",
                domProps: {
                  textContent: _vm._s(
                    _vm.selectedIncidentTypesText +
                      " • " +
                      _vm.selectedTeamsText
                  )
                }
              }),
              _c("img", {
                staticClass: "h-7 w-7 ml-3",
                attrs: {
                  src: require("@/assets/images/filter.svg"),
                  alt: _vm.$t("general.label_options")
                }
              })
            ]
          ),
          _vm.showPanel
            ? _c("filters-panel", { on: { close: _vm.closePanel } })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }