import Morphism from 'morphism';

/**
 * Image class
 *
 * @property {?String} original
 * @property {?String} medium
 * @property {?String} small
 */

export default class Image {
  constructor() {
    this.original = null;
    this.medium = null;
    this.small = null;
  }

  static fromJson(json) {
    const schema = {
      original: 'original',
      medium: 'medium',
      small: 'small',
    };

    return Morphism(schema, json, Image);
  }
}
