<template>
  <div
    class="flex justify-between items-center bg-red-500 p-4"
    :class="{ 'hidden': !isOffline && !wasOffline }"
  >
    <p
      class="text-white"
      v-if="isOffline"
      v-text="$t('vconnectivity.label_no_connection')">
    </p>
    <p
      class="text-white"
      v-if="!isOffline && wasOffline"
      v-text="$t('vconnectivity.label_no_connection_in_past')">
    </p>
    <button
      class="bg-white text-red-500 py-1 px-3 rounded-full focus:outline-none focus:shadow-lg"
      v-text="$t('general.button_reload')"
      @click="reload">
    </button>
  </div>
</template>

<script>
  export default {
    name: 'VConnectivity',
    data() {
      return {
        wasOffline: !navigator.onLine,
        isOffline: !navigator.onLine,
      };
    },
    watch: {
      isOffline() {
        if (this.isOffline) {
          this.wasOffline = true;
        }
      },
    },
    mounted() {
      window.addEventListener('online', () => {
        this.isOffline = !navigator.onLine;
      });

      window.addEventListener('offline', () => {
        this.isOffline = !navigator.onLine;
      });
    },
    methods: {
      reload() {
        window.location.reload();
      },
    },
  };
</script>
