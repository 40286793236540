import Morphism from 'morphism';
import moment from 'moment';
import User from './User';
import Link from './Link';
import Incident from './Incident';

/**
 * Message class
 *
 * @property {?Number} id
 * @property {?String} type
 * @property {?Object} content
 *
 * @property {?Date} createdAt
 *
 * @property {?User} user
 * @property {?Incident} Incident
 *
 * @property {?Array<Link>} links
 */

export default class Message {
  constructor() {
    this.id = null;
    this.type = null;
    this.content = null;

    this.createdAt = null;

    this.user = null;
    this.incident = null;

    this.links = null;
  }

  get createdAt() {
    return this._createdAt !== null ? moment(this._createdAt) : null;
  }

  set createdAt(value) {
    this._createdAt = value;
  }

  static fromJson(json) {
    const schema = {
      id: 'id',
      type: 'type',
      content: 'content',
      createdAt: 'created_at',
      user: (data) => {
        if (!data.user) {
          return null;
        }

        return User.fromJson(data.user);
      },
      incident: (data) => {
        if (!data.incident) {
          return null;
        }

        return Incident.fromJson(data.incident);
      },
      links: (data) => {
        if (!data._links) {
          return [];
        }

        return Link.fromJson(data._links);
      },
    };

    return Morphism(schema, json, Message);
  }
}
