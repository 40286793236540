<template>
  <div class="w-full flex justify-center relative">
    <div class="w-1/2 absolute bottom-0 bg-white shadow-md rounded-t-lg">
      <div class="relative pt-2">
        <button
          class="absolute top-0 right-0 m-1 focus:outline-none"
          @click="close"
        >
          <img
            src="@/assets/images/close.svg"
            :alt="$t('general.button_close')"
            class="w-5 h-5"
          >
        </button>
        <div class="px-6 py-8">
          <div
            v-if="user !== null"
            class="flex items-center justify-between">
            <div class="flex items-center">
              <img
                v-if="user.avatar !== null"
                :src="user.avatar.small"
                :alt="$t('general.label_profile_picture')"
                class="w-10 h-10 rounded-full"
              >
              <img
                v-else
                src="@/assets/images/avatar_placeholder.svg"
                :alt="$t('general.label_profile_picture')"
                class="h-10 w-10 rounded-full"
              >
              <div class="flex flex-col leading-tight ml-4">
                <p
                  v-text="user.name"
                  class="font-semibold truncate max-w-full">
                </p>
                <p
                  v-text="user.teams.map(team => team.name).join('•')"
                  class="text-gray-500 uppercase text-xs truncate max-w-full">
                </p>
              </div>
            </div>
            <div class="flex flex-col justify-end items-center ml-4 leading-tight">
              <p
                v-text="assignments"
                class="text-gray-500 truncate max-w-full">
              </p>
              <button
                v-if="incident"
                v-text="!isAssigned ? $t('general.button_assign') : $t('general.button_assigned')"
                class="btn rounded-full max-w-full px-3 py-1 border-0 uppercase
                  focus:outline-none text-xs tracking-wider truncate"
                :class="{
                  'btn-loading': assigning,
                  'btn-blue': !isAssigned,
                  'bg-gray-200 text-blue-500': isAssigned || !incident.canAssign,
                  'cursor-not-allowed': !incident.canAssign,
                }"
                :disabled="!canAssign || assigning || isAssigned"
                @click="assign"
              ></button>
            </div>
          </div>
          <div
            class="-mt-16"
            v-else
          >
            <v-loading/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VLoading from '@/components/common/VLoading.vue';

  export default {
    name: 'UserInfoWindow',
    components: {
      VLoading,
    },
    props: {
      id: { type: Number, default: null },
    },
    data() {
      return {
        assigning: false,
        isAssignedNow: false,
      };
    },
    computed: {
      event() {
        return this.$store.getters['auth/event'];
      },
      user() {
        return this.$store.getters['user/item'];
      },
      isAssigned() {
        return this.incident.assignedUsers.some(assignedUser => assignedUser.id === this.user.id)
          || this.isAssignedNow;
      },
      incident() {
        return this.$store.getters['incident/item'];
      },
      assignments() {
        return this.$t('user_info_window.label_assignment_count', {
          amount: this.user.assignedIncidentsCount + (this.isAssignedNow ? 1 : 0),
        });
      },
      canAssign() {
        return !this.isAssignedNow && this.incident && this.incident.canAssign;
      },
    },
    watch: {
      incident(newIncident, oldIncident) {
        if (newIncident && oldIncident && newIncident.id === oldIncident.id) {
          return;
        }

        this.isAssignedNow = false;

        this.loadUser();
      },
      id() {
        this.$store.commit('user/clearItem');

        this.isAssignedNow = false;

        this.loadUser();
      },
    },
    mounted() {
      this.loadUser();
    },
    beforeDestroy() {
      this.$store.commit('user/clearItem');
    },
    methods: {
      loadUser() {
        this.$store.dispatch('user/loadById', {
          id: this.id,
          eventId: this.event.id,
        });
      },
      assign() {
        if (!this.canAssign) {
          return;
        }

        this.assigning = true;

        this.$store
          .dispatch('incident/assignUser', {
            user: this.user,
            incident: this.incident,
          })
          .then(() => {
            this.assigning = false;
            this.isAssignedNow = true;
          });
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
